import styled from "styled-components";
import Furigana from "../furigana";
import ResponsiveTable from "../responsiveTable";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

const StyledMainPaper = styled(Paper)`
  scroll-margin: 70px;
  padding: 10px;
  margin-bottom: 10px;

  @media (min-width: 641px) {
    padding: 20px;
  }
`;

const StyledPaper = styled(Paper)`
  padding: 10px;
  text-align: center;

  @media (min-width: 641px) {
    padding: 20px;
    margin: 0 20%;
  }
`;

const StyledYB = styled.b`
  color: #ffc107;
`;

const StyledBB = styled.b`
  color: #0d6efd;
`;

const StyledRB = styled.b`
  color: #dc3545;
`;

const Adjectives = () => {
  return (
    <StyledMainPaper elevation={3} id="adjectives">
      <Typography variant="h4" gutterBottom>
        Les Adjectifs
      </Typography>
      <StyledMainPaper>
        <Typography variant="h5" gutterBottom>
          Adjectif en <StyledYB>～い</StyledYB>
        </Typography>
        <StyledPaper variant="outlined">
          <Typography component={"span"} variant="body1">
            <Furigana center text="[山_やま]は[大_おお]きいです" />
          </Typography>
          <Typography variant="subtitle2">La montagne est grande</Typography>
        </StyledPaper>
        <ResponsiveTable
          times={["Passé", "Présent"]}
          affirmatives={[
            <>
              <StyledBB>おおき</StyledBB>
              <StyledYB>かった</StyledYB>
              <StyledRB>です</StyledRB>
            </>,
            <>
              <StyledBB>おおき</StyledBB>
              <StyledYB>い</StyledYB>
              <StyledRB>です</StyledRB>
            </>,
          ]}
          negatives={[
            <>
              <StyledBB>おおき</StyledBB>
              <StyledYB>くなかった</StyledYB>
              <StyledRB>です</StyledRB>
            </>,
            <>
              <StyledBB>おおき</StyledBB>
              <StyledYB>くない</StyledYB>
              <StyledRB>です</StyledRB>
            </>,
          ]}
          multiples={
            <>
              <StyledBB>おおき</StyledBB>
              <StyledYB>くて</StyledYB>
              <StyledBB>おも</StyledBB>
              <StyledYB>い</StyledYB>
              <StyledRB>です</StyledRB>
            </>
          }
        />
      </StyledMainPaper>
      <StyledMainPaper>
        <Typography variant="h5" gutterBottom>
          Adjectif en <StyledYB>～い</StyledYB> + <StyledRB>すぎます</StyledRB>{" "}
          (trop)
        </Typography>
        <StyledPaper variant="outlined">
          <Typography component={"span"} variant="body1">
            <Furigana center text="[山_やま]は[大_おお]きすぎます" />
          </Typography>
          <Typography variant="subtitle2">
            La montagne est trop grande
          </Typography>
        </StyledPaper>
        <ResponsiveTable
          times={["Passé", "Présent"]}
          affirmatives={[
            <>
              <StyledBB>おおき</StyledBB>
              <StyledRB>すぎました</StyledRB>
            </>,
            <>
              <StyledBB>おおき</StyledBB>
              <StyledRB>すぎます</StyledRB>
            </>,
          ]}
          negatives={[
            <>
              <StyledBB>おおき</StyledBB>
              <StyledRB>すぎませんでした</StyledRB>
            </>,
            <>
              <StyledBB>おおき</StyledBB>
              <StyledRB>すぎません</StyledRB>
            </>,
          ]}
        />
      </StyledMainPaper>
      <StyledMainPaper>
        <Typography variant="h5" gutterBottom>
          Adjectif en <StyledYB>～な</StyledYB>
        </Typography>
        <StyledPaper variant="outlined">
          <Typography component={"span"} variant="body1">
            <Furigana center text="[山_やま]はきれいです" />
          </Typography>
          <Typography variant="subtitle2">La montagne est belle</Typography>
        </StyledPaper>
        <ResponsiveTable
          times={["Passé", "Présent"]}
          affirmatives={[
            <>
              <StyledBB>きれい</StyledBB>
              <StyledRB>でした</StyledRB>
            </>,
            <>
              <StyledBB>きれい</StyledBB>
              <StyledRB>です</StyledRB>
            </>,
          ]}
          negatives={[
            <>
              <div>
                <StyledBB>きれい</StyledBB>
                <StyledYB>じゃ</StyledYB>
                <StyledRB>ありませんでした</StyledRB>
              </div>
              <div>
                <StyledBB>きれい</StyledBB>
                <StyledYB>じゃなかった</StyledYB>
                <StyledRB>です</StyledRB>
              </div>
            </>,
            <>
              <div>
                <StyledBB>きれい</StyledBB>
                <StyledYB>じゃ</StyledYB>
                <StyledRB>ありません</StyledRB>
              </div>
              <div>
                <StyledBB>きれい</StyledBB>
                <StyledYB>じゃない</StyledYB>
                <StyledRB>です</StyledRB>
              </div>
            </>,
          ]}
        />
      </StyledMainPaper>
      <StyledMainPaper>
        <Typography variant="h5" gutterBottom>
          Nom utilisé comme Adjectif
        </Typography>
        <StyledPaper variant="outlined">
          <Typography component={"span"} variant="body1">
            <Furigana center text="[山_やま]は[雪_ゆき]です" />
          </Typography>
          <Typography variant="subtitle2">La montagne est enneigé</Typography>
        </StyledPaper>
        <ResponsiveTable
          times={["Passé", "Présent"]}
          affirmatives={[
            <>
              <StyledBB>ゆき</StyledBB>
              <StyledRB>でした</StyledRB>
            </>,
            <>
              <StyledBB>ゆき</StyledBB>
              <StyledRB>です</StyledRB>
            </>,
          ]}
          negatives={[
            <>
              <div>
                <StyledBB>ゆき</StyledBB>
                <StyledYB>じゃ</StyledYB>
                <StyledRB>ありませんでした</StyledRB>
              </div>
              <div>
                <StyledBB>ゆき</StyledBB>
                <StyledYB>じゃなかった</StyledYB>
                <StyledRB>です</StyledRB>
              </div>
            </>,
            <>
              <div>
                <StyledBB>ゆき</StyledBB>
                <StyledYB>じゃ</StyledYB>
                <StyledRB>ありません</StyledRB>
              </div>
              <div>
                <StyledBB>ゆき</StyledBB>
                <StyledYB>じゃない</StyledYB>
                <StyledRB>です</StyledRB>
              </div>
            </>,
          ]}
        />
      </StyledMainPaper>
    </StyledMainPaper>
  );
};

export default Adjectives;
