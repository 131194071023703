import styled from "styled-components";
import { useState, useEffect } from "react";
import Furigana from "../../components/furigana";
import Dialog from "@mui/material/Dialog";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";

const StyledCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FormVocabulary = ({
  selected,
  types,
  open,
  setOpen,
  create,
  update,
  remove,
}) => {
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState();
  const [japanese, setJapanese] = useState();
  const [romanji, setRomanji] = useState();
  const [french, setFrench] = useState();
  const [note, setNote] = useState();
  const [order, setOrder] = useState();

  useEffect(() => {
    setType(selected?.attributes.type);
    setJapanese(selected?.attributes.japanese);
    setRomanji(selected?.attributes.romanji);
    setFrench(selected?.attributes.french);
    setNote(selected?.attributes.note);
    setOrder(selected?.attributes.order);
  }, [selected]);

  const action = () => {
    setLoading(true);
    (selected?.id
      ? update(selected.id, {
          ...(type && { type }),
          ...(japanese && { japanese }),
          ...(romanji && { romanji }),
          ...(french && { french }),
          ...(note && { note }),
          ...(order && { order }),
        })
      : create({
          ...(type && { type }),
          ...(japanese && { japanese }),
          ...(romanji && { romanji }),
          ...(french && { french }),
          ...(note && { note }),
          ...(order && { order }),
        })
    )
      .then(() => setOpen(false))
      .finally(() => setLoading(false));
  };

  const actionRemove = () => {
    setLoading(true);
    remove(selected.id)
      .then(() => {
        setOpen(false);
        setConfirm(false);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Card sx={{ width: 300 }}>
          <CardContent>
            <Autocomplete
              freeSolo
              options={types}
              value={type || ""}
              onInputChange={(event, value) => setType(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Type"
                  required
                  disabled={!open || loading}
                  error={
                    type === "number" ||
                    type === "minute" ||
                    type === "hour" ||
                    type === "day" ||
                    type === "week" ||
                    type === "month" ||
                    type === "conjugation"
                  }
                  fullWidth
                  size="small"
                  sx={{ marginBottom: "10px" }}
                />
              )}
            />
            <TextField
              label="Japonais"
              defaultValue={japanese}
              onChange={(e) => setJapanese(e.target.value)}
              required
              disabled={!open || loading}
              fullWidth
              size="small"
              sx={{ marginBottom: "10px" }}
            />
            <TextField
              label="Romanji"
              defaultValue={romanji}
              onChange={(e) => setRomanji(e.target.value)}
              required
              disabled={!open || loading}
              fullWidth
              size="small"
              sx={{ marginBottom: "10px" }}
            />
            <TextField
              label="Français"
              defaultValue={french}
              onChange={(e) => setFrench(e.target.value)}
              required
              disabled={!open || loading}
              fullWidth
              size="small"
              sx={{ marginBottom: "10px" }}
            />
            <TextField
              label="Note"
              defaultValue={note}
              onChange={(e) => setNote(e.target.value)}
              disabled={!open || loading}
              fullWidth
              size="small"
              sx={{ marginBottom: "10px" }}
            />
            <TextField
              label="Ordre"
              defaultValue={order}
              onChange={(e) => setOrder(e.target.value)}
              disabled={!open || loading}
              type="number"
              fullWidth
              size="small"
              sx={{ marginBottom: "10px" }}
            />
          </CardContent>
          <CardActions sx={{ display: "flex", justifyContent: "space-evenly" }}>
            {selected?.id && (
              <Button
                variant="outlined"
                color="error"
                onClick={() => setConfirm(true)}
                disabled={!open || loading}
                size="small"
              >
                Supprimer
              </Button>
            )}
            <Button
              variant="outlined"
              color="success"
              onClick={() => action()}
              disabled={
                !open ||
                loading ||
                !type ||
                !japanese ||
                !romanji ||
                !french ||
                type === "number" ||
                type === "minute" ||
                type === "hour" ||
                type === "day" ||
                type === "week" ||
                type === "month" ||
                type === "conjugation"
              }
              size="small"
            >
              {selected?.id ? "Modifier" : "Créer"}
            </Button>
          </CardActions>
        </Card>
      </Dialog>
      <Dialog open={confirm} onClose={() => setConfirm(false)}>
        <Card>
          <StyledCardContent>
            <Typography variant="body1">
              Êtes-vous sûr de vouloir supprimer:
            </Typography>
            <Typography variant="subtitle1">{french}</Typography>
            <Furigana center text={japanese} />
          </StyledCardContent>
          <CardActions sx={{ display: "flex", justifyContent: "space-evenly" }}>
            <Button
              variant="outlined"
              color="success"
              onClick={() => setConfirm(false)}
              disabled={!open || loading}
              size="small"
            >
              Annuler
            </Button>

            <Button
              variant="outlined"
              color="error"
              onClick={() => actionRemove()}
              disabled={!open || loading}
              size="small"
            >
              Supprimer
            </Button>
          </CardActions>
        </Card>
      </Dialog>
    </>
  );
};

export default FormVocabulary;
