import axios from "axios";
import { API, BEARER } from "../constant";
import { getToken } from "../helpers";

const token = getToken();

export const getVocabulary = async ({ userId, type }) => {
  let filters = `filters[$and][0][$or][0][userId][$null]=true&`;
  if (userId) filters += `filters[$and][0][$or][1][userId][$eq]=${userId}&`;

  switch (type) {
    case "numbers":
      filters += "filters[$and][1][type][$eq]=number&";
      break;
    case "dates":
      filters +=
        "filters[$and][1][$or][0][type][$eq]=minute&" +
        "filters[$and][1][$or][1][type][$eq]=hour&" +
        "filters[$and][1][$or][2][type][$eq]=day&" +
        "filters[$and][1][$or][3][type][$eq]=week&" +
        "filters[$and][1][$or][4][type][$eq]=month&";
      break;
    case "conjugation":
      filters += "filters[$and][1][type][$eq]=conjugation&";
      break;
    default:
      filters +=
        "filters[$and][1][$and][0][type][$ne]=number&" +
        "filters[$and][1][$and][1][type][$ne]=minute&" +
        "filters[$and][1][$and][2][type][$ne]=hour&" +
        "filters[$and][1][$and][3][type][$ne]=day&" +
        "filters[$and][1][$and][4][type][$ne]=week&" +
        "filters[$and][1][$and][5][type][$ne]=month&" +
        "filters[$and][1][$and][6][type][$ne]=conjugation&";
      break;
  }

  let url = "/api/vocabularies?";
  let pagination = "pagination[pageSize]=100&pagination[page]=";

  return await axios.get(`${API}${url}${filters}${pagination}1`).then(
    ({
      data: {
        data,
        meta: {
          pagination: { total },
        },
      },
    }) =>
      Promise.all(
        [...Array((total + 99 - ((total - 1) % 100)) / 100 + 1).keys()]
          .slice(2)
          .map((i) => axios.get(`${API}${url}${filters}${pagination}${i}`))
      ).then((nextdata) =>
        nextdata.reduce((acc, curr) => [...acc, ...curr.data.data], [...data])
      )
  );
};

export const postVocabulary = async ({ userId, vocabulary }) => {
  return await axios
    .post(
      `${API}/api/vocabularies`,
      { data: { userId, ...vocabulary } },
      { headers: { Authorization: `${BEARER} ${token}` } }
    )
    .then(({ data }) => data)
    .catch((err) => console.error(err))
    .finally(() => {});
};

export const putVocabulary = async (id, vocabulary) => {
  const result = await axios
    .put(
      `${API}/api/vocabularies/${id}`,
      { data: vocabulary },
      { headers: { Authorization: `${BEARER} ${token}` } }
    )
    .then(({ data }) => data)
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {});
  return result;
};

export const deleteVocabulary = async (id) => {
  return await axios
    .delete(`${API}/api/vocabularies/${id}`, {
      headers: { Authorization: `${BEARER} ${token}` },
    })
    .then(({ data }) => data)
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {});
};
