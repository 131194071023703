import { useState } from "react";
import Dialog from "@mui/material/Dialog";

const Easteregg = () => {
  const [open, setOpen] = useState(false);

  const chain =
    "ArrowUp" +
    "ArrowUp" +
    "ArrowDown" +
    "ArrowDown" +
    "ArrowLeft" +
    "ArrowRight" +
    "ArrowLeft" +
    "ArrowRight" +
    "b" +
    "a";
  let input = "";
  document.addEventListener("keydown", ({ key }) => {
    input += key;
    if (input === chain) {
      setOpen(true);
      setTimeout(() => setOpen(false), 4000);
      input = "";
    } else if (chain.indexOf(input) !== 0) input = "";
  });

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <img
        src={`https://nihongonomemo.fr/assets/ganbatte.gif`}
        alt="Ganbatte !"
        className="w-100"
      />
    </Dialog>
  );
};

export default Easteregg;
