import { useAuthContext } from "../hooks/auth/AuthContext";
import { useSettingsContext } from "../hooks/settings/SettingsContext";
import Furigana from "../components/furigana";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import EditOffIcon from "@mui/icons-material/EditOff";

const TableVocabulary = ({
  selectedType,
  vocabulary,
  learn,
  updateLearn,
  updateLearnByType,
  setSelected,
  setOpenForm,
}) => {
  const { user } = useAuthContext();
  const { furigana, romanji, admin } = useSettingsContext();

  // const typeName = (type) => {
  //   switch (type) {
  //     case "number":
  //       return "nombres";
  //     case "minute":
  //       return "minutes";
  //     case "hour":
  //       return "heures";
  //     case "day":
  //       return "jours";
  //     case "week":
  //       return "semaine";
  //     case "month":
  //       return "mois";
  //     default:
  //       return type.replaceAll("_", " ");
  //   }
  // };

  return (
    <TableContainer sx={{ height: "calc(100% - 40px)" }}>
      {selectedType ? (
        <>
          <Table stickyHeader>
            <TableHead>
              <TableRow sx={{ height: "40px" }}>
                <TableCell padding="none" align="center" sx={{ width: "50%" }}>
                  Japonais
                </TableCell>
                <TableCell
                  padding="none"
                  align="center"
                  colSpan={3}
                  sx={{ width: "50%" }}
                >
                  Français
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vocabulary
                .filter((d) => d.attributes.type === selectedType)
                .toSorted((a, b) => a.order - b.order)
                .map(({ id, attributes }, index) => {
                  return (
                    <TableRow
                      key={`row${index}`}
                      sx={{
                        height: romanji ? "80px" : "50px",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "#ffffff20",
                        },
                        ...(learn.indexOf(id) > -1
                          ? {
                              backgroundColor: "#0d6efd50",
                              "&:hover": {
                                backgroundColor: "#0d6efd80",
                              },
                            }
                          : {}),
                      }}
                    >
                      <TableCell
                        padding="none"
                        align="center"
                        onClick={() => updateLearn(id)}
                      >
                        <Furigana
                          furigana={furigana}
                          center
                          text={attributes.japanese}
                        />
                        {romanji && (
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "#ffffff80" }}
                          >
                            {attributes.romanji}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell
                        sx={{ width: "10%" }}
                        onClick={() => updateLearn(id)}
                      />
                      <TableCell
                        padding="none"
                        align="center"
                        onClick={() => updateLearn(id)}
                      >
                        <Typography sx={{ margin: "0 5px" }}>
                          {attributes.french}
                        </Typography>
                      </TableCell>
                      <TableCell
                        padding="none"
                        align="center"
                        sx={{ width: "10%" }}
                      >
                        {user && admin ? (
                          <IconButton
                            variant="outlined"
                            onClick={() => {
                              setSelected({ id, attributes });
                              setOpenForm(true);
                            }}
                            disabled={!attributes.userId}
                            size="small"
                          >
                            {attributes.userId ? <EditIcon /> : <EditOffIcon />}
                          </IconButton>
                        ) : (
                          attributes.note && (
                            <Tooltip title={attributes.note} placement="left">
                              <InfoIcon sx={{ color: "#ffffff80" }} />
                            </Tooltip>
                          )
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </>
      ) : (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Aucunes données
        </Box>
      )}
    </TableContainer>
  );
};

export default TableVocabulary;
