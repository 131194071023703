import styled from "styled-components";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAuthContext } from "../hooks/auth/AuthContext";
import useDebounce from "../hooks/use-debounce";
import {
  getVocabulary,
  postVocabulary,
  putVocabulary,
  deleteVocabulary,
} from "../services/vocabulary";
import TableVocabulary from "../components/tableVocabulary";
import ExerciseVocabulary from "../components/modal/exerciseVocabulary";
import FormVocabulary from "../components/modal/formVocabulary";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import CreateIcon from "@mui/icons-material/Create";

const StyledPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-height: 100dvh;
  padding: 70px 10px 20px;

  @media (min-width: 641px) {
    padding: 70px 20px 20px;
  }
`;

const StyledMainPaper = styled(Paper)`
  height: calc(100dvh - 90px);
  width: 100%;
  max-width: 600px;
  padding: 10px;

  @media (min-width: 641px) {
    padding: 20px;
  }
`;

const StyledProgress = styled.div`
  z-index: 999;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff3b;
`;

const StyledBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;

  @media (min-width: 641px) {
    margin-top: -10px;
  }
`;

const Vocabulary = () => {
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const { user, isLoading } = useAuthContext();
  const { debounce, search } = useDebounce();

  const [loading, setLoading] = useState(false);
  const [vocabulary, setVocabulary] = useState([]);
  const [filteredVocabulary, setFilteredVocabulary] = useState([]);
  const [learn, setLearn] = useState([]);
  const [openExerciceVocabulary, setOpenExerciceVocabulary] = useState(false);
  const [exerciseStep, setExerciseStep] = useState(0);
  const [selectedVocabulary, setSelectedVocabulary] = useState();
  const [openFormVocabulary, setOpenFormVocabulary] = useState(false);
  const [selectedType, setSelectedType] = useState(undefined);

  useEffect(() => {
    debounce("");
    setLoading(true);
    setLearn([]);
    if (isLoading === false)
      getVocabulary({ type, userId: user?.id })
        .then((data) => {
          setVocabulary(data);
          setSelectedType(data[0].attributes.type);
          // [...new Set(data.map((d) => d.attributes.type))][0]
        })
        .catch((err) => {})
        .finally(() => {
          setLoading(false);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isLoading, type]);

  useEffect(() => {
    setFilteredVocabulary(
      search
        ? vocabulary.filter(
            ({ attributes: { japanese, romanji, french, type } }) =>
              japanese.toLowerCase().search(search.toLowerCase()) > -1 ||
              romanji.toLowerCase().search(search.toLowerCase()) > -1 ||
              french.toLowerCase().search(search.toLowerCase()) > -1 ||
              type.toLowerCase().search(search.toLowerCase()) > -1
          )
        : vocabulary
    );
  }, [vocabulary, search]);

  const updateLearn = (id) => {
    setLearn((l) =>
      l.indexOf(id) > -1
        ? l.reduce((a, c) => [...a, ...(c === id ? [] : [c])], [])
        : [...l, id]
    );
  };

  const updateLearnByType = (type) => {
    const ids = vocabulary
      .filter((d) => d.attributes.type === type)
      .map((d) => d.id);
    setLearn(
      ids.every((id) => learn.indexOf(id) > -1)
        ? learn.reduce(
            (a, c) => [...a, ...(ids.indexOf(c) > -1 ? [] : [c])],
            []
          )
        : [...new Set([...learn, ...ids])]
    );
  };

  const createVocabulary = async (vocabulary) => {
    return postVocabulary({ userId: user.id, vocabulary }).then(({ data }) =>
      setVocabulary((v) => [...v, data])
    );
  };

  const updateVocabulary = async (id, vocabulary) => {
    return putVocabulary(id, vocabulary).then(({ data }) =>
      setVocabulary((v) => v.map((v) => (v.id === data.id ? data : v)))
    );
  };

  const removeVocabulary = async (id) => {
    return deleteVocabulary(id).then(({ data }) => {
      setVocabulary((v) => v.filter((v) => v.id !== data.id));
      setLearn(learn.reduce((a, c) => [...a, ...(c === id ? [] : [c])], []));
    });
  };

  return (
    <StyledPage>
      <StyledMainPaper elevation={3}>
        {loading ? (
          <StyledProgress>
            <CircularProgress />
          </StyledProgress>
        ) : (
          <>
            <StyledBox>
              <FormControl size="small">
                <InputLabel>Catégorie</InputLabel>
                <Select
                  label="Catégorie"
                  defaultValue={selectedType}
                  onChange={(e) => setSelectedType(e.target.value)}
                >
                  {[...new Set(vocabulary.map((v) => v.attributes.type))].map(
                    (type, index) => (
                      <MenuItem key={index} value={type}>
                        {type}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <SearchIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                <TextField
                  label="Rechercher"
                  size="small"
                  onChange={({ target }) => debounce(target.value)}
                />
              </Box>
            </StyledBox>
            <TableVocabulary
              selectedType={selectedType}
              vocabulary={filteredVocabulary}
              learn={learn}
              updateLearn={updateLearn}
              updateLearnByType={updateLearnByType}
              setSelected={setSelectedVocabulary}
              setOpenForm={setOpenFormVocabulary}
            />
          </>
        )}
      </StyledMainPaper>
      <SpeedDial
        ariaLabel="Exercice"
        sx={{
          position: "absolute",
          bottom: 10,
          right: 10,
        }}
        icon={<MoreVertIcon />}
      >
        <SpeedDialAction
          icon={<FitnessCenterIcon />}
          tooltipTitle={"Exercice"}
          disabled={learn.length < 5}
          onClick={() => {
            setExerciseStep(0);
            setOpenExerciceVocabulary(true);
          }}
        />
        {user && !type && (
          <SpeedDialAction
            icon={<CreateIcon />}
            tooltipTitle={"Ajouter"}
            onClick={() => {
              setSelectedVocabulary();
              setOpenFormVocabulary(true);
            }}
          />
        )}
      </SpeedDial>
      <ExerciseVocabulary
        vocabulary={vocabulary}
        learn={learn}
        open={openExerciceVocabulary}
        setOpen={setOpenExerciceVocabulary}
        step={exerciseStep}
        setStep={setExerciseStep}
      />
      <FormVocabulary
        selected={selectedVocabulary}
        types={[...new Set(vocabulary.map((d) => d.attributes.type))]}
        open={openFormVocabulary}
        setOpen={setOpenFormVocabulary}
        create={createVocabulary}
        update={updateVocabulary}
        remove={removeVocabulary}
      />
    </StyledPage>
  );
};

export default Vocabulary;
