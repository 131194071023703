import { useAuthContext } from "../../hooks/auth/AuthContext";
import { useSettingsContext } from "../../hooks/settings/SettingsContext";
import styled from "styled-components";
import Furigana from "../furigana.js";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";

const StyledList = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 200px;
`;

const StyledPaper = styled(Paper)`
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

const StyledExemple = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 50px;
`;

const StyledElement = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Settings = () => {
  const { user } = useAuthContext();
  const { furigana, romanji, admin, setFurigana, setRomanji, setAdmin } =
    useSettingsContext();

  return (
    <StyledList>
      <StyledPaper>
        <StyledExemple>
          <EditIcon style={{ visibility: "hidden" }} />
          <StyledElement>
            <Furigana text={furigana ? "[日本語_にほんご]" : "日本語"} />
            {romanji && (
              <Typography variant="subtitle2" sx={{ color: "#ffffff80" }}>
                nihongo
              </Typography>
            )}
          </StyledElement>
          {admin ? <EditIcon /> : <InfoIcon sx={{ color: "#ffffff80" }} />}
        </StyledExemple>
      </StyledPaper>
      <StyledPaper>
        <Typography variant="body1">Furigana:</Typography>
        <ToggleButtonGroup
          value={furigana}
          exclusive
          fullWidth
          onChange={(event, value) =>
            setFurigana((v) => (value !== null ? value : v))
          }
        >
          <ToggleButton size="small" value={true}>
            Avec
          </ToggleButton>
          <ToggleButton size="small" value={false}>
            Sans
          </ToggleButton>
        </ToggleButtonGroup>
      </StyledPaper>
      <StyledPaper>
        <Typography variant="body1">Romanji:</Typography>
        <ToggleButtonGroup
          value={romanji}
          exclusive
          fullWidth
          onChange={(event, value) =>
            setRomanji((v) => (value !== null ? value : v))
          }
        >
          <ToggleButton size="small" value={true}>
            Avec
          </ToggleButton>
          <ToggleButton size="small" value={false}>
            Sans
          </ToggleButton>
        </ToggleButtonGroup>
      </StyledPaper>
      {user && (
        <StyledPaper>
          <Typography variant="body1">Bouton modifier:</Typography>
          <ToggleButtonGroup
            value={admin}
            exclusive
            fullWidth
            onChange={(event, value) =>
              setAdmin((v) => (value !== null ? value : v))
            }
          >
            <ToggleButton size="small" value={true}>
              Avec
            </ToggleButton>
            <ToggleButton size="small" value={false}>
              Sans
            </ToggleButton>
          </ToggleButtonGroup>
        </StyledPaper>
      )}
    </StyledList>
  );
};

export default Settings;
