import React, { useState, useEffect } from "react";
import { SettingsContext } from "./SettingsContext";

const SettingsProvider = ({ children }) => {
  const [furigana, setFurigana] = useState(
    localStorage.getItem("setting_furigana")
      ? JSON.parse(localStorage.getItem("setting_furigana"))
      : true
  );
  const [romanji, setRomanji] = useState(
    localStorage.getItem("setting_romanji")
      ? JSON.parse(localStorage.getItem("setting_romanji"))
      : true
  );
  const [admin, setAdmin] = useState(
    localStorage.getItem("setting_admin")
      ? JSON.parse(localStorage.getItem("setting_admin"))
      : false
  );

  useEffect(() => {
    if (furigana !== JSON.parse(localStorage.getItem("setting_furigana")))
      localStorage.setItem("setting_furigana", furigana);
    if (romanji !== JSON.parse(localStorage.getItem("setting_romanji")))
      localStorage.setItem("setting_romanji", romanji);
    if (admin !== JSON.parse(localStorage.getItem("setting_admin")))
      localStorage.setItem("setting_admin", admin);
  }, [furigana, romanji, admin]);

  return (
    <SettingsContext.Provider
      value={{
        furigana,
        romanji,
        admin,
        setFurigana,
        setRomanji,
        setAdmin,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsProvider;
