import styled from "styled-components";
import Profile from "./profile.js";
import Settings from "./settings.js";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
// import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Dialog from "@mui/material/Dialog";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";

import HomeIcon from "@mui/icons-material/Home";
import SchoolIcon from "@mui/icons-material/School";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
// import MailIcon from "@mui/icons-material/Mail";
// import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
// import ListIcon from "@mui/icons-material/List";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import TranslateIcon from "@mui/icons-material/Translate";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Filter1Icon from "@mui/icons-material/Filter1";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AbcIcon from "@mui/icons-material/Abc";
import ScheduleIcon from "@mui/icons-material/Schedule";

const StyledCardHeader = styled(CardHeader)`
  padding: 5px;
`;

const sitemap = [
  { label: "Accueil", link: "/", icon: <HomeIcon /> },
  {
    label: "Cours",
    link: "/learn",
    icon: <SchoolIcon />,
    sub: [
      {
        label: "Les Particules",
        link: "#particles",
        icon: <MenuBookIcon />,
        sub: [
          { label: "ha (は)", link: "#ha" },
          { label: "mo (も)", link: "#mo" },
          { label: "no (の)", link: "#no" },
          { label: "wo (を)", link: "#wo" },
          { label: "ga (が)", link: "#ga" },
          { label: "ni (に)", link: "#ni" },
          { label: "de (で)", link: "#de" },
          { label: "to (と)", link: "#to" },
          { label: "ya (や)", link: "#ya" },
          { label: "kara et made (からetまで)", link: "#karamade" },
          { label: "ne et yo (ねetよ)", link: "#neyo" },
        ],
      },
      { label: "Les Adjectifs", link: "#adjectives", icon: <MenuBookIcon /> },
      { label: "La Conjugaison", link: "#conjugation", icon: <MenuBookIcon /> },
      { label: "Les Structures", link: "#structures", icon: <MenuBookIcon /> },
      { label: "Notes", link: "#notes", icon: <MenuBookIcon /> },
    ],
  },
  { label: "Kana", link: "/kana", icon: <TranslateIcon /> },
  { label: "Nombres", link: "/vocabulary?type=numbers", icon: <Filter1Icon /> },
  { label: "Dates", link: "/vocabulary?type=dates", icon: <DateRangeIcon /> },
  { label: "Vocabulaire", link: "/vocabulary", icon: <AbcIcon /> },
  { label: "Verbes", link: "/conjugation", icon: <ScheduleIcon /> },
];

const Header = () => {
  const navigate = useNavigate();
  const { pathname, search, hash } = useLocation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [title, setTitle] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);

  const isMenuOpen = Boolean(anchorEl);

  useEffect(() => {
    switch (pathname) {
      case "/learn":
        setTitle("Cours");
        break;
      default:
        setTitle("");
    }
  }, [pathname]);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              onClick={() => setOpenDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
            <StyledCardHeader
              avatar={
                <Avatar
                  src={`https://nihongonomemo.fr/assets/flag.png`}
                ></Avatar>
              }
              title={<Typography variant="h6">NihongoNoMemo</Typography>}
              subheader={title}
            />
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              {/* <IconButton
                size="large"
                aria-label="show 4 new mails"
                color="inherit"
              >
                <Badge badgeContent={4} color="error">
                  <MailIcon />
                </Badge>
              </IconButton>
              <IconButton
                size="large"
                aria-label="notifications"
                color="inherit"
              >
                <Badge badgeContent={17} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton> */}
              <IconButton
                size="large"
                aria-label="account"
                aria-haspopup="true"
                onClick={() => setOpenProfile(true)}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <IconButton
                size="large"
                edge="end"
                aria-label="settings"
                aria-haspopup="true"
                onClick={() => setOpenSettings(true)}
              >
                <SettingsIcon />
              </IconButton>
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls="menu"
                aria-haspopup="true"
                onClick={(e) => setAnchorEl(e.currentTarget)}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          id="menu"
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={isMenuOpen}
          onClose={() => setAnchorEl(null)}
        >
          {/* <MenuItem>
            <IconButton
              size="large"
              aria-label="show 4 new mails"
              color="inherit"
            >
              <Badge badgeContent={4} color="error">
                <MailIcon />
              </Badge>
            </IconButton>
            Messages
          </MenuItem>
          <MenuItem>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <Badge badgeContent={17} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            Notifications
          </MenuItem> */}
          <MenuItem
            onClick={() => {
              setOpenProfile(true);
              setAnchorEl(null);
            }}
          >
            <IconButton size="large">
              <AccountCircle />
            </IconButton>
            Profil
          </MenuItem>
          <MenuItem
            onClick={() => {
              setOpenSettings(true);
              setAnchorEl(null);
            }}
          >
            <IconButton size="large">
              <SettingsIcon />
            </IconButton>
            Réglages
          </MenuItem>
        </Menu>
      </Box>
      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <Box
          sx={{ width: 220 }}
          role="presentation"
          // onClick={() => setOpenDrawer(false)}
        >
          <List>
            {sitemap.map(({ label, link, icon, sub }, index) => (
              <div key={`page${index}`}>
                <ListItem disablePadding dense>
                  <ListItemButton
                    onClick={() => navigate(link)}
                    selected={pathname + search === link}
                  >
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText primary={label} />
                    {sub &&
                      (pathname === link ? <ExpandLess /> : <ExpandMore />)}
                  </ListItemButton>
                </ListItem>
                {sub && (
                  <Collapse in={pathname === link} timeout="auto" unmountOnExit>
                    <List disablePadding dense>
                      {sub.map(({ label, link, icon }, index) => (
                        <ListItem key={`sub${index}`} disablePadding dense>
                          <ListItemButton
                            sx={{ pl: 4 }}
                            onClick={() => navigate(link)}
                            selected={hash === link}
                          >
                            <ListItemIcon>{icon}</ListItemIcon>
                            <ListItemText primary={label} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                )}
              </div>
            ))}
          </List>
          <Divider />
        </Box>
      </Drawer>
      <Dialog open={openProfile} onClose={() => setOpenProfile(false)}>
        <Profile />
      </Dialog>
      <Dialog open={openSettings} onClose={() => setOpenSettings(false)}>
        <Settings />
      </Dialog>
    </>
  );
};

export default Header;
