import { createContext, useContext } from "react";

export const SettingsContext = createContext({
  furigana: true,
  romanji: true,
  admin: false,
  setFurigana: () => {},
  setRomanji: () => {},
  setAdmin: () => {},
});

export const useSettingsContext = () => useContext(SettingsContext);
