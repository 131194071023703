import "./App.scss";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import AuthProvider from "./hooks/auth/AuthProvider";
import SettingsProvider from "./hooks/settings/SettingsProvider";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Easteregg from "./components/global/easteregg";
import Header from "./components/global/header";
import Home from "./pages/home";
import Learn from "./pages/learn";
import Kana from "./pages/kana";
import Vocabulary from "./pages/vocabulary";
import Conjugation from "./pages/conjugation";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const App = () => {
  return (
    <div className="App">
      <ThemeProvider theme={darkTheme}>
        <AuthProvider>
          <SettingsProvider>
            <Easteregg />
            <BrowserRouter>
              <Header />
              <Routes>
                <Route index element={<Home />} />
                <Route path="/learn" element={<Learn />} />
                <Route path="/kana" element={<Kana />} />
                <Route
                  path="/vocabulary?type=numbers"
                  element={<Vocabulary />}
                />
                <Route path="/vocabulary?type=dates" element={<Vocabulary />} />
                <Route path="/vocabulary" element={<Vocabulary />} />
                <Route path="/conjugation" element={<Conjugation />} />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </BrowserRouter>
          </SettingsProvider>
        </AuthProvider>
      </ThemeProvider>
    </div>
  );
};

export default App;
