import styled from "styled-components";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

const StyledMainPaper = styled(Paper)`
  scroll-margin: 70px;
  padding: 10px;
  margin-bottom: 10px;

  @media (min-width: 641px) {
    padding: 20px;
  }
`;
const StyledPaper = styled(Paper)`
  padding: 10px;

  @media (min-width: 641px) {
    padding: 20px;
    width: fit-content;
  }
`;

const StyledDivider = styled(Divider)`
  margin: 20px 30%;
`;

const StyledYB = styled.b`
  color: #ffc107;
`;

const StyledTB = styled.b`
  color: #0dcaf0;
`;

const StyledBB = styled.b`
  color: #0d6efd;
`;

const Particles = () => {
  return (
    <StyledMainPaper elevation={3} id="particles">
      <Typography variant="h4" gutterBottom>
        Les Particules
      </Typography>
      <StyledMainPaper>
        {/* HA */}
        <Typography variant="h5" gutterBottom>
          Sujet + <StyledYB>は</StyledYB>
        </Typography>
        <p>
          La particule <StyledYB>は</StyledYB> indique le sujet et le thème de
          la phrase.
        </p>
        <StyledPaper variant="outlined">
          <Typography variant="body1">
            わたし<StyledYB>は</StyledYB>やまだです。
          </Typography>
          <Typography variant="subtitle2">Je m'appelle Yamada.</Typography>
          <StyledDivider />
          <Typography variant="body1">
            わたし<StyledYB>は</StyledYB>まいにちにほんご
            <StyledTB>を</StyledTB>
            べんきょうします。
          </Typography>
          <Typography variant="subtitle2">
            J'étudie le japonais tous les jours.
          </Typography>
        </StyledPaper>
      </StyledMainPaper>
      <StyledMainPaper>
        {/* MO */}
        <Typography variant="h5" gutterBottom>
          Nom + <StyledYB>も</StyledYB>
        </Typography>
        <p>
          La particule <StyledYB>も</StyledYB> signifie « aussi » et elle peut
          remplacer les particules <StyledTB>は</StyledTB>,{" "}
          <StyledTB>が</StyledTB> et <StyledTB>を</StyledTB>.
        </p>
        <StyledPaper variant="outlined">
          <Typography variant="body1">
            やまださん<StyledTB>は</StyledTB>にほんじんです。 さとうさん
            <StyledYB>も</StyledYB>
            にほんじんです。
          </Typography>
          <Typography variant="subtitle2">
            Yamada est japonais. Sato est aussi japonais.
          </Typography>
          <StyledDivider />
          <Typography variant="body1">
            わたし<StyledTB>は</StyledTB>ロック
            <StyledYB>も</StyledYB>すきです。
          </Typography>
          <Typography variant="subtitle2">J'aime aussi le rock.</Typography>
        </StyledPaper>
        <StyledDivider />
        <Typography variant="h5" gutterBottom>
          Interrogatif + <StyledYB>も</StyledYB>
        </Typography>
        <p>
          La particule <StyledYB>も</StyledYB> peut s'attacher aux mots
          interrogatifs comme <StyledBB>どこ</StyledBB>,{" "}
          <StyledBB>なに</StyledBB>, <StyledBB>だれ</StyledBB> etc., ce qui
          signifie qu'il n'y a aucun cas qui s'applique. Et cela mène
          obligatoirement au verbe à la forme négative.
        </p>
        <StyledPaper variant="outlined">
          <Typography variant="body1">
            きのう<StyledBB>なに</StyledBB>
            <StyledYB>も</StyledYB>たべませんでした。
          </Typography>
          <Typography variant="subtitle2">Je n'ai rien mangé hier.</Typography>
          <StyledDivider />
          <Typography variant="body1">
            きょうしつ<StyledTB>に</StyledTB>
            <StyledBB>だれ</StyledBB>
            <StyledYB>も</StyledYB>いません。
          </Typography>
          <Typography variant="subtitle2">
            Il n'y a personne dans la classe.
          </Typography>
        </StyledPaper>
      </StyledMainPaper>
      <StyledMainPaper>
        {/* NO */}
        <Typography variant="h5" gutterBottom>
          Nom1 + <StyledYB>の</StyledYB> + Nom2
        </Typography>
        <p>
          La particule <StyledYB>の</StyledYB> a pour rôle de relier deux noms
          et elle peut représenter la possession, l'appartenance, le fabricant,
          le contenu etc.
        </p>
        <StyledPaper variant="outlined">
          <Typography variant="body1">
            それ<StyledTB>は</StyledTB>わたし
            <StyledYB>の</StyledYB>かばんです。
          </Typography>
          <Typography variant="subtitle2">C'est mon sac.</Typography>
          <StyledDivider />
          <Typography variant="body1">
            これ<StyledTB>は</StyledTB>にほんご
            <StyledYB>の</StyledYB>ほんです。
          </Typography>
          <Typography variant="subtitle2">
            C'est un livre de japonais.
          </Typography>
        </StyledPaper>
        <StyledDivider />
        <Typography variant="h5" gutterBottom>
          Verbe + <StyledYB>の</StyledYB>
        </Typography>
        <p>
          La particule <StyledYB>の</StyledYB> a aussi pour rôle de nominaliser
          un verbe afin de le placer avant la particule.
        </p>
        <StyledPaper variant="outlined">
          <Typography variant="body1">
            わたし<StyledTB>は</StyledTB>サッカー
            <StyledTB>を</StyledTB>する
            <StyledYB>の</StyledYB>
            <StyledTB>が</StyledTB>すきです。
          </Typography>
          <Typography variant="subtitle2">J'aime jouer au foot.</Typography>
          <StyledDivider />
          <Typography variant="body1">
            えいご<StyledTB>を</StyledTB>はなす
            <StyledYB>の</StyledYB>
            <StyledTB>わ</StyledTB>かんたんです。
          </Typography>
          <Typography variant="subtitle2">
            Il est facile de parler anglais.
          </Typography>
        </StyledPaper>
      </StyledMainPaper>
      <StyledMainPaper>
        {/* WO */}
        <Typography variant="h5" gutterBottom>
          Objet + <StyledYB>を</StyledYB>
        </Typography>
        <p>
          Beaucoup de verbes japonais sont accompagnés de la particule{" "}
          <StyledYB>を</StyledYB> qui permet d'avoir un COD.
        </p>
        <StyledPaper variant="outlined">
          <Typography variant="body1">
            わたし<StyledTB>は</StyledTB>コーヒー
            <StyledYB>を</StyledYB>のみます。
          </Typography>
          <Typography variant="subtitle2">Je bois du café.</Typography>
          <StyledDivider />
          <Typography variant="body1">
            きのうテレビ<StyledYB>を</StyledYB>みました。
          </Typography>
          <Typography variant="subtitle2">
            J'ai regardé la télévision hier.
          </Typography>
        </StyledPaper>
      </StyledMainPaper>
      <StyledMainPaper>
        {/* GA */}
        <Typography variant="h5" gutterBottom>
          Objet + <StyledYB>が</StyledYB> + <StyledBB>すき</StyledBB> /{" "}
          <StyledBB>じょうず</StyledBB> / <StyledBB>できます</StyledBB> …
        </Typography>
        <p>
          La particule <StyledYB>が</StyledYB> indique le complément d'objet
          direct des mots exprimant la préférence, la compétence, le désir comme
          suit.
        </p>
        <StyledPaper variant="outlined">
          <Typography variant="body1">
            やまださん<StyledTB>は</StyledTB>フランスご
            <StyledYB>が</StyledYB>わかります。
          </Typography>
          <Typography variant="subtitle2">
            Yamada comprend le français.
          </Typography>
          <StyledDivider />
          <Typography variant="body1">
            すずきさん<StyledTB>は</StyledTB>やきゅう
            <StyledYB>が</StyledYB>じょうずです。
          </Typography>
          <Typography variant="subtitle2">
            Suzuki joue bien au baseball.
          </Typography>
        </StyledPaper>
        <StyledDivider />
        <Typography variant="h5" gutterBottom>
          Nom + <StyledYB>が</StyledYB> + <StyledBB>あります</StyledBB> /{" "}
          <StyledBB>います</StyledBB>
        </Typography>
        <p>
          Elle est également utilisée avec les verbes{" "}
          <StyledBB>あります</StyledBB> et <StyledBB>います</StyledBB> voulant
          dire "il y a" ou « avoir ».
        </p>
        <StyledPaper variant="outlined">
          <Typography variant="body1">
            うち<StyledTB>の</StyledTB>ちかく
            <StyledTB>に</StyledTB>おてら
            <StyledYB>が</StyledYB>
            <StyledBB>あります</StyledBB>。
          </Typography>
          <Typography variant="subtitle2">
            Il y a de temples bouddhistes près de chez moi.
          </Typography>
          <StyledDivider />
          <Typography variant="body1">
            こうえん<StyledTB>に</StyledTB>こども
            <StyledYB>が</StyledYB>
            <StyledBB>います</StyledBB>。
          </Typography>
          <Typography variant="subtitle2">
            Il y a des enfants dans le parc.
          </Typography>
        </StyledPaper>
        <StyledDivider />
        <Typography variant="h5" gutterBottom>
          Nom1 + <StyledTB>は</StyledTB> + Nom2 + <StyledYB>が</StyledYB> +
          Adjectif
        </Typography>
        <p>
          Elle est également utilisée avec les verbes{" "}
          <StyledBB>あります</StyledBB> et <StyledBB>います</StyledBB> voulant
          dire "il y a" ou « avoir ».
        </p>
        <StyledPaper variant="outlined">
          <Typography variant="body1">
            フランスご<StyledTB>は</StyledTB>ぶんぽう
            <StyledYB>が</StyledYB>むずかしいです。
          </Typography>
          <Typography variant="subtitle2">
            La grammaire française est difficile.
          </Typography>
          <StyledDivider />
          <Typography variant="body1">
            やまださん<StyledTB>は</StyledTB>かみ
            <StyledYB>が</StyledYB>みじかいです。
          </Typography>
          <Typography variant="subtitle2">
            Yamada a les cheveux courts.
          </Typography>
        </StyledPaper>
        <StyledDivider />
        <Typography variant="h5" gutterBottom>
          Sujet + <StyledYB>が</StyledYB> + Verbe intransitif
        </Typography>
        <p>
          En japonais, il existe un bon nombre de paires de verbes transitifs /
          intransitifs. Et les verbes intransitifs qui font pendant au verbe
          transitif ont forcément la particule <StyledYB>が</StyledYB>.
        </p>
        <StyledPaper variant="outlined">
          <Typography variant="body1">
            ドア<StyledTB>を</StyledTB>あけます。
          </Typography>
          <Typography variant="subtitle2">
            J'ouvre la porte. (transitif)
          </Typography>
          <StyledDivider />
          <Typography variant="body1">
            ドア<StyledYB>が</StyledYB>あきます。
          </Typography>
          <Typography variant="subtitle2">
            La porte s'ouvre. (intransitif)
          </Typography>
        </StyledPaper>
        <StyledDivider />
        <Typography variant="h5" gutterBottom>
          Sujet + <StyledYB>が</StyledYB> + Verbe + Nom +{" "}
          <StyledBB>です</StyledBB>
        </Typography>
        <p>
          La particule <StyledYB>が</StyledYB> indique le sujet de la phrase qui
          qualifie un nom.
        </p>
        <StyledPaper variant="outlined">
          <Typography variant="body1">
            これ<StyledTB>は</StyledTB>さとうさん
            <StyledYB>が</StyledYB>つくったケーキです。
          </Typography>
          <Typography variant="subtitle2">
            C'est le gâteau que Sato a fait.
          </Typography>
          <StyledDivider />
          <Typography variant="body1">
            これ<StyledYB>が</StyledYB>
            しゅっぱつしたでんしゃです。
          </Typography>
          <Typography variant="subtitle2">
            C'est le train qui est parti.
          </Typography>
        </StyledPaper>
      </StyledMainPaper>
      <StyledMainPaper>
        {/* NI */}
        <Typography variant="h5" gutterBottom>
          Temps + <StyledYB>に</StyledYB> + Verbe
        </Typography>
        <p>
          La particule <StyledYB>に</StyledYB> s'attache à un nom exprimant le
          temps précis, et symbolise le moment où une action se passe.
        </p>
        <StyledPaper variant="outlined">
          <Typography variant="body1">
            わたし<StyledTB>は</StyledTB>６じ
            <StyledYB>に</StyledYB>おきます。
          </Typography>
          <Typography variant="subtitle2">Je me lève à six heures.</Typography>
          <StyledDivider />
          <Typography variant="body1">
            よるおそく<StyledYB>に</StyledYB>たべます。
          </Typography>
          <Typography variant="subtitle2">Je mange tard le soir.</Typography>
        </StyledPaper>
        <StyledDivider />
        <Typography variant="h5" gutterBottom>
          Lieu + <StyledYB>に</StyledYB>
        </Typography>
        <p>
          <StyledYB>に</StyledYB> indique aussi un lieu où quelque
          chose/quelqu'un existe.
        </p>{" "}
        <StyledPaper variant="outlined">
          <Typography variant="body1">
            わたし<StyledTB>の</StyledTB>まち
            <StyledYB>に</StyledYB>おしろ
            <StyledTB>が</StyledTB>あります。
          </Typography>{" "}
          <Typography variant="subtitle2">
            Il y a un château dans ma ville.
          </Typography>
          <StyledDivider />
          <Typography variant="body1">
            わたし<StyledTB>は</StyledTB>トゥールーズ
            <StyledYB>に</StyledYB>すんでいます。
          </Typography>
          <Typography variant="subtitle2">J'habite à Toulouse.</Typography>
        </StyledPaper>
        <StyledDivider />
        <Typography variant="h5" gutterBottom>
          Lieu + <StyledYB>に</StyledYB> + Verbe de déplacement
        </Typography>
        <p>
          Quand <StyledYB>に</StyledYB> accompagne les verbes de déplacement
          comme <StyledBB>いきます</StyledBB>, <StyledBB>きます</StyledBB>,{" "}
          <StyledBB>かえります</StyledBB> etc., cela exprime la destination vers
          laquelle le sujet se dirige.
        </p>
        <StyledPaper variant="outlined">
          <Typography variant="body1">
            あしたおおさか<StyledYB>に</StyledYB>
            <StyledBB>いきます</StyledBB>。
          </Typography>
          <Typography variant="subtitle2">J'irai à Osaka demain.</Typography>
          <StyledDivider />
          <Typography variant="body1">
            こんやわたし<StyledTB>は</StyledTB>あるいていえ
            <StyledYB>に</StyledYB>
            <StyledBB>かえります</StyledBB>。
          </Typography>
          <Typography variant="subtitle2">
            Ce soir je rentre à pied à ma maison.
          </Typography>
        </StyledPaper>
        <StyledDivider />
        <Typography variant="h5" gutterBottom>
          Verbe + <StyledYB>に</StyledYB> + <StyledBB>いきます</StyledBB>
        </Typography>
        <p>
          La particule <StyledYB>に</StyledYB> peut exprimer le but (aller
          quelque part afin d'y réaliser une action). Dans cette utilisation,{" "}
          <StyledYB>に</StyledYB> s'attache au verbe mais pas au nom.
        </p>
        <StyledPaper variant="outlined">
          <Typography variant="body1">
            ひるごはん<StyledTB>を</StyledTB>たべ
            <StyledYB>に</StyledYB>
            <StyledBB>いきます</StyledBB>。
          </Typography>
          <Typography variant="subtitle2">
            Je vais aller manger mon déjeuner.
          </Typography>
          <StyledDivider />
          <Typography variant="body1">
            いえ<StyledTB>の</StyledTB>そうじ
            <StyledYB>に</StyledYB>いきます。
          </Typography>
          <Typography variant="subtitle2">
            Je vais faire le ménage chez moi.
          </Typography>
        </StyledPaper>
        <StyledDivider />
        <Typography variant="h5" gutterBottom>
          <StyledYB>に</StyledYB> + <StyledBB>あげます</StyledBB> /{" "}
          <StyledBB>もらいます</StyledBB>
        </Typography>
        <p>
          Les deux verbes <StyledBB>あげます</StyledBB> et{" "}
          <StyledBB>もらいます</StyledBB> ont la particule{" "}
          <StyledYB>に</StyledYB> qui indique "à qui" l'on donne et "de qui"
          l'on reçoit. Les verbes dans le même ordre d'idée (
          <StyledBB>かします</StyledBB>, <StyledBB>かります</StyledBB>,{" "}
          <StyledBB>おしえます</StyledBB>, <StyledBB>ならいます</StyledBB>,{" "}
          <StyledBB>おくります</StyledBB>) prennent aussi la particule ni.
        </p>
        <StyledPaper variant="outlined">
          <Typography variant="body1">
            わたし<StyledTB>は</StyledTB>はは
            <StyledYB>に</StyledYB>プレゼント
            <StyledTB>を</StyledTB>
            <StyledBB>あげました</StyledBB>。
          </Typography>
          <Typography variant="subtitle2">
            J'ai donné un cadeau à ma mère.
          </Typography>
          <StyledDivider />
          <Typography variant="body1">
            わたし<StyledTB>は</StyledTB>あに
            <StyledYB>に</StyledYB>くるま
            <StyledTB>を</StyledTB>
            <StyledBB>もらいました</StyledBB>。
          </Typography>
          <Typography variant="subtitle2">
            J'ai reçu une voiture de mon grand frère.
          </Typography>
        </StyledPaper>
      </StyledMainPaper>
      <StyledMainPaper>
        {/* DE */}
        <Typography variant="h5" gutterBottom>
          Lieu + <StyledYB>で</StyledYB>
        </Typography>
        <p>
          La particule <StyledYB>で</StyledYB> s'attache à un nom indiquant le
          lieu où une action se passe. A la différence de "Lieu +{" "}
          <StyledTB>に</StyledTB>", la particule de est employée avec les verbes
          indiquant une action.
        </p>
        <StyledPaper variant="outlined">
          <Typography variant="body1">
            こうえん<StyledYB>で</StyledYB>サッカー
            <StyledTB>を</StyledTB>します。
          </Typography>
          <Typography variant="subtitle2">Je joue au foot au parc.</Typography>
          <StyledDivider />
          <Typography variant="body1">
            がっこう<StyledYB>で</StyledYB>べんきょうします。
          </Typography>
          <Typography variant="subtitle2">J'étudie à l'école.</Typography>
        </StyledPaper>
        <StyledDivider />
        <Typography variant="h5" gutterBottom>
          Moyen + <StyledYB>で</StyledYB>
        </Typography>
        <p>
          La particule <StyledYB>で</StyledYB> indique le moyen d'exécution
          d'une action.
        </p>
        <StyledPaper variant="outlined">
          <Typography variant="body1">
            わたし<StyledTB>は</StyledTB>まいにち バス
            <StyledYB>で</StyledYB>がっこう
            <StyledTB>に</StyledTB>いきます。
          </Typography>
          <Typography variant="subtitle2">
            Tous les jours, je vais à l'école en bus.
          </Typography>
          <StyledDivider />
          <Typography variant="body1">
            わたし<StyledTB>は</StyledTB>はし
            <StyledYB>で</StyledYB>スパゲティ
            <StyledTB>を</StyledTB>たべます。
          </Typography>
          <Typography variant="subtitle2">
            Je mange des spaghettis avec des baguettes.
          </Typography>
        </StyledPaper>
        <StyledDivider />
        <Typography variant="h5" gutterBottom>
          Catégorie + <StyledYB>で</StyledYB>
        </Typography>
        <p>
          <StyledYB>で</StyledYB> est aussi employée pour marquer une catégorie
          notamment dans la structure superlative.
        </p>
        <StyledPaper variant="outlined">
          <Typography variant="body1">
            にほんりょうり<StyledYB>で</StyledYB>てんぷら
            <StyledTB>が</StyledTB>
            いちばんおいしいです。
          </Typography>
          <Typography variant="subtitle2">
            Parmi les plats japonais, les tempuras sont les meilleurs.
          </Typography>
          <StyledDivider />
          <Typography variant="body1">
            いちねん<StyledYB>で</StyledYB>８がつ
            <StyledTB>が</StyledTB>いちばん あついです。
          </Typography>
          <Typography variant="subtitle2">
            Au cours de l'année, il fait le plus chaud en août.
          </Typography>
        </StyledPaper>
        <StyledDivider />
        <Typography variant="h5" gutterBottom>
          Cause / Raison + <StyledYB>で</StyledYB>
        </Typography>
        <p>
          Les noms avec la particule <StyledYB>で</StyledYB> peuvent indiquer la
          cause/la raison de la conséquence qui suit.
        </p>
        <StyledPaper variant="outlined">
          <Typography variant="body1">
            すごいあめ<StyledYB>で</StyledYB>でんしゃ
            <StyledTB>が</StyledTB>とまりました。
          </Typography>
          <Typography variant="subtitle2">
            Le train a été bloqué à cause de la forte pluie.
          </Typography>
          <StyledDivider />
          <Typography variant="body1">
            せんせい<StyledTB>の</StyledTB>おかげ
            <StyledYB>で</StyledYB>にほんご
            <StyledTB>が</StyledTB>はなせるよう
            <StyledTB>に</StyledTB>なりました。
          </Typography>
          <Typography variant="subtitle2">
            Maintenant, je parle Japonais grâce à mon enseignante.
          </Typography>
        </StyledPaper>
      </StyledMainPaper>
      <StyledMainPaper>
        {/* TO */}
        <Typography variant="h5" gutterBottom>
          Nom1 + <StyledYB>と</StyledYB> + Nom2
        </Typography>
        <p>
          La particule <StyledYB>と</StyledYB> relie deux noms et signifie "et"
          en français.
        </p>
        <StyledPaper variant="outlined">
          <Typography variant="body1">
            わたし<StyledTB>は</StyledTB>ロック
            <StyledYB>と</StyledYB>ジャズ
            <StyledTB>が</StyledTB>すきです。
          </Typography>
          <Typography variant="subtitle2">
            J'aime le rock et le jazz.
          </Typography>
          <StyledDivider />
          <Typography variant="body1">
            わたし<StyledTB>は</StyledTB>にほんご
            <StyledYB>と</StyledYB>えいご
            <StyledTB>を</StyledTB>べんきょうします。
          </Typography>
          <Typography variant="subtitle2">
            J'etudie le Japonais et l'Anglais.
          </Typography>
        </StyledPaper>
        <StyledDivider />
        <Typography variant="h5" gutterBottom>
          Nom + <StyledYB>と</StyledYB> + Verbe
        </Typography>
        <p>
          La particule <StyledYB>と</StyledYB> peut également signifier "avec
          quelqu'un" dans la phrase verbale.
        </p>
        <StyledPaper variant="outlined">
          <Typography variant="body1">
            きのうかぞく<StyledYB>と</StyledYB>えいが
            <StyledTB>を</StyledTB>みました。
          </Typography>
          <Typography variant="subtitle2">
            J'ai regardé un film avec ma famille hier.
          </Typography>
          <StyledDivider />
          <Typography variant="body1">
            こうえん<StyledTB>で</StyledTB>いぬ
            <StyledYB>と</StyledYB>さんぽします。
          </Typography>
          <Typography variant="subtitle2">
            Je me promène avec mon chien dans le parc.
          </Typography>
        </StyledPaper>
      </StyledMainPaper>
      <StyledMainPaper>
        {/* YA */}
        <Typography variant="h5" gutterBottom>
          Nom + <StyledYB>や</StyledYB> + Verbe
        </Typography>
        <p>
          La particule <StyledYB>や</StyledYB> qui signifie aussi "et", est
          utilisée quand la liste est non exhaustive.
        </p>
        <StyledPaper variant="outlined">
          <Typography variant="body1">
            うち<StyledTB>の</StyledTB>ちかく
            <StyledTB>に</StyledTB>みせ
            <StyledYB>や</StyledYB>レストラン
            <StyledTB>が</StyledTB>あります。
          </Typography>
          <Typography variant="subtitle2">
            Il y a des magasins et des restaurants près de chez moi.
          </Typography>
          <StyledDivider />
          <Typography variant="body1">
            いぬ<StyledYB>や</StyledYB>ねこ
            <StyledTB>が</StyledTB>すき。
          </Typography>
          <Typography variant="subtitle2">
            J'aime les chiens et les chats.
          </Typography>
        </StyledPaper>
      </StyledMainPaper>
      <StyledMainPaper>
        {/* KARA et MADE */}
        <Typography variant="h5" gutterBottom>
          Temps / Lieu + <StyledYB>から</StyledYB> + Temps / Lieu +{" "}
          <StyledYB>まで</StyledYB>
        </Typography>
        <p>
          Les particules <StyledYB>から</StyledYB> et <StyledYB>まで</StyledYB>{" "}
          sont des particules représentant respectivement le point de départ et
          le point d'arrivée.
        </p>
        <StyledPaper variant="outlined">
          <Typography variant="body1">
            うち<StyledYB>から</StyledYB>かいしゃ
            <StyledYB>まで</StyledYB>バス
            <StyledTB>で</StyledTB>いきます。
          </Typography>
          <Typography variant="subtitle2">
            De chez moi à mon bureau, je vais en bus.
          </Typography>
          <StyledDivider />
          <Typography variant="body1">
            わたし<StyledTB>は</StyledTB>９じ
            <StyledYB>から</StyledYB>はたらきます。
          </Typography>
          <Typography variant="subtitle2">
            Je travaille à partir de 9 heures.
          </Typography>
        </StyledPaper>
      </StyledMainPaper>
      <StyledMainPaper>
        {/* NE et YO */}
        <Typography variant="h5" gutterBottom>
          Verbe + <StyledYB>ね</StyledYB> / <StyledYB>よ</StyledYB>
        </Typography>
        <p>
          La particule <StyledYB>ね</StyledYB> sert à exprimer la sympathie ou
          afin d'acquiescer à une remarque. La particule <StyledYB>よ</StyledYB>{" "}
          est utilisée quand on veut accentuer une information que
          l'interlocuteur ne connaît pas. Elles se placent toujours à la fin
          d'une phrase.
        </p>
        <StyledPaper variant="outlined">
          <Typography variant="body1">
            きょう<StyledTB>は</StyledTB>さむいです
            <StyledYB>ね</StyledYB>。
          </Typography>
          <Typography variant="subtitle2">
            Il fait froid aujourd'hui, n'est-ce pas ?
          </Typography>
          <StyledDivider />
          <Typography variant="body1">
            みてください。ほし<StyledTB>が</StyledTB>
            たくさんでています
            <StyledYB>よ</StyledYB>。
          </Typography>
          <Typography variant="subtitle2">
            Regardez. Il y a beaucoup d'étoiles dans le ciel.
          </Typography>
        </StyledPaper>
      </StyledMainPaper>
    </StyledMainPaper>
  );
};

export default Particles;
