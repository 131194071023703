import styled from "styled-components";
import { useEffect, useState } from "react";
import { useAuthContext } from "../../hooks/auth/AuthContext";
import { getNote } from "../../services/note";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

const StyledMainPaper = styled(Paper)`
  scroll-margin: 70px;
  padding: 10px;
  margin-bottom: 10px;

  @media (min-width: 641px) {
    padding: 20px;
  }
`;

const StyledPaper = styled(Paper)`
  padding: 10px;

  @media (min-width: 641px) {
    padding: 20px;
    width: fit-content;
  }
`;

const StyledBox = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
`;

const StyledProgress = styled.div`
  z-index: 999;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff3b;
`;

const Notes = () => {
  const { user, isLoading } = useAuthContext();

  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    setLoading(true);
    if (isLoading === false)
      getNote({ userId: user?.id })
        .then((data) => setNotes(data))
        .catch((err) => {})
        .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isLoading]);

  // const createNote = async (note) => {
  //   return postNote({ userId: user.id, note }).then(({ data }) =>
  //     setNotes((v) => [...v, data])
  //   );
  // };

  // const updateNote = async (id, note) => {
  //   return putNote(id, note).then(({ data }) =>
  //     setNotes((v) => v.map((v) => (v.id === data.id ? data : v)))
  //   );
  // };

  return (
    <StyledMainPaper elevation={3} id="notes">
      <Typography variant="h4" gutterBottom>
        Notes
      </Typography>
      <StyledMainPaper>
        <StyledBox>
          {loading ? (
            <StyledProgress>
              <CircularProgress />
            </StyledProgress>
          ) : (
            notes.map(({ attributes: { japanese, french } }, index) => (
              <StyledPaper key={`note${index}`} variant="outlined">
                <Typography variant="body1">{japanese}</Typography>
                <Typography variant="subtitle2">{french}</Typography>
              </StyledPaper>
            ))
          )}
        </StyledBox>
      </StyledMainPaper>
    </StyledMainPaper>
  );
};

export default Notes;
