import { useEffect, useState } from "react";
import Cards from "../components/exerciseKana/cards";
import Result from "../components/exerciseKana/result";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

const ExerciseKana = ({ data, learn, open, setOpen, step, setStep }) => {
  const [exercises, setExercises] = useState([]);
  const [leave, setLeave] = useState(false);

  const shuffle = (list) =>
    list
      .map((value) => ({ value, sort: Math.random() }))
      .toSorted((a, b) => a.sort - b.sort)
      .map(({ value }) => value);

  useEffect(() => {
    let types = ["cards"];
    if (open)
      setExercises([
        ...shuffle(learn).map((id) => ({
          type: types[Math.floor(Math.random() * types.length)],
          id,
          result: null,
        })),
        {
          type: "result",
        },
      ]);
  }, [open, setExercises, learn]);

  return (
    <Dialog fullScreen open={open} onClose={() => setLeave(true)}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6" component="div">
            Exercice
          </Typography>
          <IconButton
            onClick={() =>
              step === exercises.length - 1 ? setOpen(false) : setLeave(true)
            }
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {exercises.map(({ type, id, result }, index) => {
          switch (type) {
            case "cards":
              return (
                <Cards
                  key={`exercise${index}`}
                  hidden={step !== index}
                  data={data}
                  id={id}
                  result={result}
                  setExercises={setExercises}
                  setStep={setStep}
                />
              );
            default:
              return (
                <Result
                  key={`exercise${index}`}
                  hidden={step !== index}
                  exercises={exercises}
                  setExercises={setExercises}
                  open={open}
                  setOpen={setOpen}
                />
              );
          }
        })}
        <LinearProgress
          variant="determinate"
          value={(step / (exercises.length - 1)) * 100}
          sx={{ width: "90%", maxWidth: "500px" }}
        />
      </Box>
      <Dialog open={leave} onClose={() => setLeave(false)}>
        <DialogTitle>{"Êtes-vous sûr de vouloir quitter?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>L'exercice sera annulé.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLeave(false)}>Continuer</Button>
          <Button
            color="error"
            onClick={() => {
              setLeave(false);
              setOpen(false);
              setExercises((ex) => ex.map((e) => ({ ...e, result: null })));
            }}
          >
            Quitter
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default ExerciseKana;
