import styled from "styled-components";
import { useState } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;

  @media (min-width: 641px) {
    display: none;
  }
`;

const StyledTableHead = styled(TableHead)`
  @media (max-width: 641px) {
    display: none;
  }
`;

const StyledTableCell = styled(TableCell)`
  @media (max-width: 641px) {
    ${({ $hidden }) => ($hidden ? "display:none" : "")};
  }
`;

const ResponsiveTable = ({
  title,
  times,
  affirmatives,
  negatives,
  multiples,
}) => {
  const [tab, setTab] = useState(1);

  return (
    <Paper variant="outlined" sx={{ marginTop: "10px" }}>
      <StyledBox sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Typography variant="body1" sx={{ width: "40%", marginLeft: "10px" }}>
          {title}
        </Typography>
        <Tabs
          value={tab}
          onChange={(e, value) => setTab(value)}
          variant="scrollable"
          scrollButtons
        >
          {times.map((time, index) => (
            <Tab key={`mobiletime${index}`} label={time} />
          ))}
        </Tabs>
      </StyledBox>
      <Table>
        <StyledTableHead>
          <TableRow>
            <TableCell>{title}</TableCell>
            {times.map((time, index) => (
              <TableCell key={`screentime${index}`} align="center">
                {time}
              </TableCell>
            ))}
          </TableRow>
        </StyledTableHead>
        <TableBody>
          <TableRow>
            <TableCell>Affirmatif</TableCell>
            {affirmatives.map((affirmative, index) => (
              <StyledTableCell
                key={`affirmative${index}`}
                align="center"
                $hidden={tab !== index}
              >
                {affirmative}
              </StyledTableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>Négatif</TableCell>
            {negatives.map((negative, index) => (
              <StyledTableCell
                key={`negative${index}`}
                align="center"
                $hidden={tab !== index}
              >
                {negative}
              </StyledTableCell>
            ))}
          </TableRow>
          {multiples && (
            <TableRow>
              <TableCell>Multiples</TableCell>
              {multiples.length ? (
                multiples.map((multiple, index) => (
                  <StyledTableCell
                    key={`multiple${index}`}
                    align="center"
                    $hidden={tab !== index}
                  >
                    {multiple}
                  </StyledTableCell>
                ))
              ) : (
                <TableCell align="center" colSpan={times.length}>
                  {multiples}
                </TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default ResponsiveTable;
