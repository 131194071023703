import { useState } from "react";

export default function useDebounce() {
  const [search, setSearch] = useState("");

  let timeout = null;

  const debounce = (data) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      setSearch(data);
    }, 500);
  };

  return {
    debounce,
    search,
  };
}
