import styled from "styled-components";
import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

const StyledImg = styled.img`
  height: 70%;
`;

const Result = ({ hidden, exercises, setExercises, open, setOpen }) => {
  const [results, setResults] = useState([]);
  const [url, setUrl] = useState("");

  useEffect(() => {
    if (open) setResults(exercises.slice(0, -1).map((e) => e.result));
  }, [open, exercises]);

  useEffect(() => {
    let result = results.reduce((a, c) => a + (c ? 1 : 0), 0) / results.length;
    let character = ["kanon", "mizyu", "rin", "suzuka"][
      Math.floor(Math.random() * 4)
    ];
    switch (true) {
      case result === 1:
        setUrl(`${character}/love.gif`);
        break;
      case result > 0.75:
        setUrl(`${character}/happy.gif`);
        break;
      case result > 0.5:
        setUrl(`${character}/base.gif`);
        break;
      case result > 0.25:
        setUrl(`${character}/stun.gif`);
        break;
      case result > 0:
        setUrl(`${character}/sleep.gif`);
        break;
      case result === 0:
        setUrl(`${character}/angry.gif`);
        break;
      default:
        break;
    }
  }, [results]);

  return (
    <Box
      sx={{
        height: "400px",
        width: "100%",
        display: hidden ? "none" : "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography variant="h4" component="div">
        {results.reduce((a, c) => a + (c ? 1 : 0), 0)} / {results.length}
      </Typography>
      <Typography variant="h6" component="div">
        {Math.round(
          (results.reduce((a, c) => a + (c ? 1 : 0), 0) / results.length) * 100
        )}
        %
      </Typography>
      <StyledImg
        src={`https://nihongonomemo.fr/assets/AGif/${url}`}
        alt="Résultat"
      />
      <Box
        sx={{
          width: "90%",
          maxWidth: "500px",
          marginBottom: "10px",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Button
          variant="text"
          onClick={() => {
            setOpen(false);
            setExercises((ex) => ex.map((e) => ({ ...e, result: null })));
          }}
        >
          Terminer
        </Button>
      </Box>
    </Box>
  );
};

export default Result;
