import { useState, useEffect, createElement } from "react";

const Furigana = ({ furigana = true, center, reverse, small, text }) => {
  const [node, setNode] = useState(<div />);

  useEffect(() => {
    const style0 = {
      margin: "5px",
      display: "flex",
      flexWrap: "nowrap",
      whiteSpace: "nowrap",
      lineHeight: 1,
      letterSpacing: "2px",
      justifyContent: center ? "center" : "flex-start",
      alignItems: reverse ? "flex-start" : "flex-end",
      fontSize: small ? "large" : "large",
    };
    const style1 = {
      letterSpacing: "1px",
      alignItems: reverse ? "flex-start" : "flex-end",
      fontSize: small ? "large" : "large",
    };
    const style2 = {
      display: "flex",
      whiteSpace: "nowrap",
      flexDirection: reverse ? "column" : "column-reverse",
      fontSize: small ? "large" : "large",
    };
    const style3 = {
      display: furigana ? "block" : "none",
      margin: "0 -25px 2px",
      letterSpacing: "0px",
      textAlign: "center",
      fontSize: small ? "small" : "small",
    };

    setNode(
      createElement(
        "div",
        { style: style0 },
        ...text
          .split(/\[|\]/)
          .filter((e) => e !== "")
          .map((e) =>
            e.search("_") < 0
              ? createElement("div", { style: style1 }, e)
              : createElement(
                  "div",
                  { style: style2 },
                  e.slice(0, e.search("_")),
                  createElement(
                    "div",
                    { style: style3 },
                    e.slice(e.search("_") + 1)
                  )
                )
          )
      )
    );
  }, [center, reverse, small, text, furigana]);

  return node;
};

export default Furigana;
