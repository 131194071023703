import styled from "styled-components";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
// import { getVocabulary } from "../services/vocabulary";
import TableVocabulary from "../components/tableVocabulary";
import ExerciseVocabulary from "../components/modal/exerciseVocabulary";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import SpeedDial from "@mui/material/SpeedDial";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import QuizIcon from "@mui/icons-material/Quiz";

const StyledPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-height: 100dvh;
  padding: 70px 10px 20px;

  @media (min-width: 641px) {
    padding: 70px 20px 20px;
  }
`;

const StyledMainPaper = styled(Paper)`
  height: calc(100dvh - 90px);
  width: 100%;
  max-width: 600px;
  padding: 10px;

  @media (min-width: 641px) {
    padding: 20px;
  }
`;

const Progress = styled.div`
  z-index: 999;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff3b;

  // TO REMOVE
  justify-content: space-evenly;
`;

const Conjugation = () => {
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");

  const [loading, setLoading] = useState(false);
  const [types, setTypes] = useState(null);
  const [data, setData] = useState(null);
  const [learn, setLearn] = useState([]);
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(0);

  useEffect(() => {
    setTypes(null); //TO REMOVE
    setData(null); //TO REMOVE

    setLoading(true);
    // getVocabulary({ type })
    //   .then((data) => {
    //     setTypes([...new Set(data.map((d) => d.attributes.type))]);
    //     setData(data);
    //   })
    //   .catch(() => {})
    //   .finally(() => setLoading(false));
  }, [type]);

  return (
    <StyledPage>
      <StyledMainPaper elevation={3}>
        {loading ? (
          <Progress>
            WIP
            <CircularProgress />
            WIP
          </Progress>
        ) : (
          <TableVocabulary
            types={types}
            data={data}
            learn={learn}
            setLearn={setLearn}
          />
        )}
      </StyledMainPaper>
      <SpeedDial
        ariaLabel="Exercice"
        sx={{ position: "absolute", bottom: 10, right: 10 }}
        icon={<FitnessCenterIcon />}
      >
        <SpeedDialAction
          icon={<QuizIcon />}
          tooltipTitle={"Exercice"}
          disabled={learn.length < 5}
          onClick={() => {
            setStep(0);
            setOpen(true);
          }}
        />
      </SpeedDial>
      <ExerciseVocabulary
        data={data}
        learn={learn}
        open={open}
        setOpen={setOpen}
        step={step}
        setStep={setStep}
      />
    </StyledPage>
  );
};

export default Conjugation;
