import styled from "styled-components";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const StyledMainPaper = styled(Paper)`
  scroll-margin: 70px;
  padding: 10px;
  margin-bottom: 10px;

  @media (min-width: 641px) {
    padding: 20px;
  }
`;

const StyledPaper = styled(Paper)`
  padding: 10px;

  @media (min-width: 641px) {
    padding: 20px;
    width: fit-content;
  }
`;

const StyledYB = styled.b`
  color: #ffc107;
`;

const StyledRB = styled.b`
  color: #dc3545;
`;

const Structures = () => {
  const dataTForm = [
    {
      title: "1er groupe",
      rows: [
        {
          base: [
            <>
              あ<StyledYB>う</StyledYB> → あ
              <s>
                <StyledYB>い</StyledYB>ます
              </s>
            </>,
            <>
              ま<StyledYB>つ</StyledYB> → ま
              <s>
                <StyledYB>ち</StyledYB>ます
              </s>
            </>,
            <>
              と<StyledYB>る</StyledYB> → と
              <s>
                <StyledYB>り</StyledYB>ます
              </s>
            </>,
          ],
          end: (
            <>
              ~<StyledYB>って</StyledYB>
            </>
          ),
        },
        {
          base: [
            <>
              はな<StyledYB>す</StyledYB> → はな
              <s>
                <StyledYB>し</StyledYB>ます
              </s>
            </>,
          ],
          end: (
            <>
              ~<StyledYB>して</StyledYB>
            </>
          ),
        },
        {
          base: [
            <>
              き<StyledYB>く</StyledYB> → き
              <s>
                <StyledYB>き</StyledYB>ます
              </s>
            </>,
          ],
          end: (
            <>
              ~<StyledYB>いて</StyledYB>
            </>
          ),
        },
        {
          base: [
            <>
              およ<StyledYB>ぐ</StyledYB> → およ
              <s>
                <StyledYB>ぎ</StyledYB>ます
              </s>
            </>,
          ],
          end: (
            <>
              ~<StyledYB>いで</StyledYB>
            </>
          ),
        },
        {
          base: [
            <>
              やす<StyledYB>む</StyledYB> → やす
              <s>
                <StyledYB>み</StyledYB>ます
              </s>
            </>,
            <>
              おそ<StyledYB>ぶ</StyledYB> → おそ
              <s>
                <StyledYB>び</StyledYB>ます
              </s>
            </>,
            <>
              し<StyledYB>ぬ</StyledYB> → し
              <s>
                <StyledYB>に</StyledYB>ます
              </s>
            </>,
          ],
          end: (
            <>
              ~<StyledYB>んで</StyledYB>
            </>
          ),
        },
        {
          base: [
            <>
              い<StyledYB>く</StyledYB> → い
              <s>
                <StyledYB>き</StyledYB>ます
              </s>
            </>,
          ],
          end: (
            <>
              ~<StyledYB>って</StyledYB>
            </>
          ),
        },
      ],
    },
    {
      title: "2eme groupe",
      rows: [
        {
          base: [
            <>
              たべ<StyledYB>る</StyledYB> → たべ<s>ます</s>
            </>,
          ],
          end: (
            <>
              ~<StyledYB>て</StyledYB>
            </>
          ),
        },
      ],
    },
    {
      title: "3eme groupe",
      rows: [
        {
          base: [
            <>
              す<StyledYB>る</StyledYB> →{" "}
              <s>
                <StyledYB>し</StyledYB>ます
              </s>
            </>,
          ],
          end: (
            <>
              ~<StyledYB>して</StyledYB>
            </>
          ),
        },
        {
          base: [
            <>
              く<StyledYB>る</StyledYB> →{" "}
              <s>
                <StyledYB>き</StyledYB>ます
              </s>
            </>,
          ],
          end: (
            <>
              ~<StyledYB>きて</StyledYB>
            </>
          ),
        },
      ],
    },
  ];

  return (
    <StyledMainPaper elevation={3} id="structures">
      <Typography variant="h4" gutterBottom>
        Les Structures
      </Typography>
      <StyledMainPaper>
        <Typography variant="h5" gutterBottom>
          <StyledYB>て</StyledYB>-form
        </Typography>
        {dataTForm.map(({ title, rows }, index) => (
          <Paper
            key={`table${index}`}
            variant="outlined"
            sx={{ marginTop: "10px" }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={2}>
                    <Typography variant="body1">{title}</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map(({ base, end }, index) => (
                  <TableRow key={`row${index}`}>
                    <TableCell align="center" sx={{ width: "55%" }}>
                      {base.map((elem, index) => (
                        <Typography key={`typo${index}`} variant="body1">
                          {elem}
                        </Typography>
                      ))}
                    </TableCell>
                    <TableCell align="center" sx={{ width: "45%" }}>
                      <Typography variant="body1">{end}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        ))}
      </StyledMainPaper>
      <StyledMainPaper>
        <Typography variant="h5" gutterBottom>
          Vouloir
        </Typography>
        <StyledPaper variant="outlined">
          <Typography variant="body1">
            たべ<StyledYB>たい</StyledYB>です。
          </Typography>
          <Typography variant="subtitle2">Je veux manger.</Typography>
        </StyledPaper>
      </StyledMainPaper>
      <StyledMainPaper>
        <Typography variant="h5" gutterBottom>
          ... Donc ...
        </Typography>
        <StyledPaper variant="outlined">
          <Typography variant="body1">
            やさいがきらいです<StyledYB>から</StyledYB>たべません。
          </Typography>
          <Typography variant="subtitle2">
            Je n'aime pas les légumes, donc je n'en mange pas.
          </Typography>
        </StyledPaper>
      </StyledMainPaper>
      <StyledMainPaper>
        <Typography variant="h5" gutterBottom>
          Demander: ...<StyledYB>て</StyledYB>
          <StyledRB>もいいですか</StyledRB>?
        </Typography>
        <StyledPaper variant="outlined">
          <Typography variant="body1">
            ここでたばこを<StyledYB>すって</StyledYB>
            <StyledRB>もいいですか</StyledRB>？
          </Typography>
          <Typography variant="subtitle2">
            Puis-je fumer du tabac ici ?
          </Typography>
        </StyledPaper>
      </StyledMainPaper>
      <StyledMainPaper>
        <Typography variant="h5" gutterBottom>
          Autoriser: ...<StyledYB>て</StyledYB>
          <StyledRB>もいいです</StyledRB>。
        </Typography>
        <StyledPaper variant="outlined">
          <Typography variant="body1">
            ここでたばこを<StyledYB>すって</StyledYB>
            <StyledRB>もいいです</StyledRB>。
          </Typography>
          <Typography variant="subtitle2">
            Vous pouvez fumer du tabac ici.
          </Typography>
        </StyledPaper>
      </StyledMainPaper>
      <StyledMainPaper>
        <Typography variant="h5" gutterBottom>
          Interdire: ...<StyledYB>て</StyledYB>
          <StyledRB>はいけません</StyledRB>。
        </Typography>
        <StyledPaper variant="outlined">
          <Typography variant="body1">
            ここでたばこを<StyledYB>すって</StyledYB>
            <StyledRB>はいけません</StyledRB>。
          </Typography>
          <Typography variant="subtitle2">
            Vous ne pouvez pas fumer du tabac ici.
          </Typography>
        </StyledPaper>
      </StyledMainPaper>
    </StyledMainPaper>
  );
};

export default Structures;
