import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Furigana from "../components/furigana";
import Box from "@mui/material/Box";

const tableKana = ({ data, learn, setLearn }) => {
  const dataByTypeFormatted = (dataByType, prefix) => {
    return Array.from({ length: 5 }, () => ({})).map((elem, index) => {
      let byPrefix = dataByType.filter((e) => e.attributes.prefix === prefix);
      if (byPrefix.length === 3 && index === 1) return elem;
      if (byPrefix.length === 3 && index === 2) return byPrefix[1];
      if (byPrefix.length === 3 && index === 3) return elem;
      if (byPrefix.length === 3 && index === 4) return byPrefix[2];

      if (byPrefix.length === 2 && index === 1) return elem;
      if (byPrefix.length === 2 && index === 2) return elem;
      if (byPrefix.length === 2 && index === 3) return elem;
      if (byPrefix.length === 2 && index === 4) return byPrefix[1];

      if (byPrefix.length === 1 && index === 0) return elem;
      if (byPrefix.length === 1 && index === 1) return elem;
      if (byPrefix.length === 1 && index === 2) return byPrefix[0];
      if (byPrefix.length === 1 && index === 3) return elem;
      if (byPrefix.length === 1 && index === 4) return elem;

      return byPrefix[index];
    });
  };

  const updateLearn = (id) => {
    if (id)
      setLearn(
        learn.indexOf(id) > -1
          ? learn.reduce((a, c) => [...a, ...(c === id ? [] : [c])], [])
          : [...learn, id]
      );
  };

  const updateLearnByPrefix = (dataByType, prefix) => {
    const ids = dataByType
      .filter((e) => e.attributes.prefix === prefix)
      .map((e) => e.id);
    setLearn(
      ids.every((id) => learn.indexOf(id) > -1)
        ? learn.reduce(
            (a, c) => [...a, ...(ids.indexOf(c) > -1 ? [] : [c])],
            []
          )
        : [...new Set([...learn, ...ids])]
    );
  };

  const updateLearnByType = (dataByType) => {
    const ids = dataByType.map((e) => e.id);
    setLearn(
      ids.every((id) => learn.indexOf(id) > -1)
        ? learn.reduce(
            (a, c) => [...a, ...(ids.indexOf(c) > -1 ? [] : [c])],
            []
          )
        : [...new Set([...learn, ...ids])]
    );
  };

  return (
    <TableContainer sx={{ height: "calc(100% - 50px)" }}>
      {data ? (
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell />
              {["A", "I", "U", "E", "O"].map((column, index) => (
                <TableCell
                  padding="none"
                  key={`column${index}`}
                  align="center"
                  sx={{ height: "40px" }}
                >
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          {data.map((dataByType, index) => (
            <TableBody key={`type${index}`}>
              <TableRow>
                <TableCell
                  sx={{
                    position: "sticky",
                    top: "40px",
                    height: "30px",
                    backgroundColor: "#121212",
                  }}
                />
                <TableCell
                  padding="none"
                  align="center"
                  colSpan={5}
                  sx={{
                    position: "sticky",
                    top: "40px",
                    height: "30px",
                    cursor: "pointer",
                    backgroundColor: "#121212",
                    "&:hover": {
                      backgroundColor: "#3f3f3f",
                    },
                    "&:first-letter": {
                      textTransform: "uppercase",
                    },
                  }}
                  onClick={() => updateLearnByType(dataByType)}
                >
                  {dataByType[0].attributes.type}
                </TableCell>
              </TableRow>
              {[...new Set(dataByType.map((e) => e.attributes.prefix))].map(
                (prefix, index) => {
                  return (
                    <TableRow key={`row${index}`}>
                      <TableCell
                        padding="none"
                        align="center"
                        sx={{
                          cursor: "pointer",
                          backgroundColor: "#121212",
                          "&:hover": {
                            backgroundColor: "#3f3f3f",
                          },
                        }}
                        onClick={() => updateLearnByPrefix(dataByType, prefix)}
                      >
                        {prefix}
                      </TableCell>
                      {dataByTypeFormatted(dataByType, prefix).map(
                        ({ id, attributes }, index) =>
                          id ? (
                            <TableCell
                              padding="none"
                              key={`cell${index}`}
                              align="center"
                              sx={{
                                cursor: "pointer",
                                "&:hover": {
                                  backgroundColor: "#ffffff20",
                                },
                                ...(learn.indexOf(id) > -1
                                  ? {
                                      backgroundColor: "#0d6efd50",
                                      "&:hover": {
                                        backgroundColor: "#0d6efd80",
                                      },
                                    }
                                  : {}),
                              }}
                              onClick={() => updateLearn(id)}
                            >
                              <Furigana
                                center
                                reverse
                                text={`[${attributes.kana}_${attributes.romanji}]`}
                              />
                            </TableCell>
                          ) : (
                            <TableCell padding="none" key={`cell${index}`} />
                          )
                      )}
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          ))}
        </Table>
      ) : (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Aucunes données
        </Box>
      )}
    </TableContainer>
  );
};

export default tableKana;
