import styled from "styled-components";
import { useState } from "react";
import { useAuthContext } from "../../hooks/auth/AuthContext";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";

import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";

const StyledList = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Profile = () => {
  const { user, isLoading, error, login, logout } = useAuthContext();

  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  return (
    <StyledList>
      <Card sx={{ width: 300 }}>
        {user && (
          <CardHeader
            avatar={<Avatar aria-label="recipe">{user.username[0]}</Avatar>}
            title={user.username}
            subheader={user.email}
            action={
              <IconButton
                aria-label="logout"
                sx={{ margin: "4px 0" }}
                onClick={() => logout()}
              >
                <LogoutIcon />
              </IconButton>
            }
          />
        )}

        {!user && (
          <CardContent>
            <FormControl
              variant="outlined"
              onChange={(e) => setIdentifier(e.target.value)}
              disabled={isLoading}
              error={error}
              fullWidth
              sx={{ marginBottom: "10px" }}
            >
              <InputLabel>Identifiant</InputLabel>
              <OutlinedInput type="text" label="Identifier" />
            </FormControl>
            <FormControl
              variant="outlined"
              onChange={(e) => setPassword(e.target.value)}
              disabled={isLoading}
              error={error}
              fullWidth
            >
              <InputLabel>Mot de passe</InputLabel>
              <OutlinedInput
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword((s) => !s)}
                      onMouseDown={(e) => e.preventDefault()}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
          </CardContent>
        )}
        {!user && (
          <CardActions sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="outlined"
              aria-label="login"
              onClick={() => login({ identifier, password })}
              disabled={isLoading}
              endIcon={<LoginIcon />}
            >
              Connexion
            </Button>
          </CardActions>
        )}
      </Card>
    </StyledList>
  );
};

export default Profile;
