import styled from "styled-components";
import { useEffect, useState } from "react";
import { getSyllabaries } from "../services/syllabary";
import TableKana from "../components/tableKana";
import ExerciseKana from "../components/exerciseKana";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CircularProgress from "@mui/material/CircularProgress";
import SpeedDial from "@mui/material/SpeedDial";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import QuizIcon from "@mui/icons-material/Quiz";

const StyledPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-height: 100dvh;
  padding: 70px 10px 20px;

  @media (min-width: 641px) {
    padding: 70px 20px 20px;
  }
`;

const StyledMainPaper = styled(Paper)`
  height: calc(100dvh - 90px);
  width: 100%;
  max-width: 600px;
  padding: 10px;

  @media (min-width: 641px) {
    padding: 20px;
  }
`;

const Progress = styled.div`
  z-index: 999;
  height: calc(100% - 50px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff3b;
`;

const Kana = () => {
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [learn, setLearn] = useState([]);
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(0);

  useEffect(() => {
    setLoading(true);
    getSyllabaries({ name: tab ? "katakana" : "hiragana" })
      .then((data) =>
        setData(
          data.map((d) =>
            d.toSorted((a, b) => a.attributes.order - b.attributes.order)
          )
        )
      )
      .catch(() => {})
      .finally(() => setLoading(false));
  }, [tab]);

  return (
    <StyledPage>
      <StyledMainPaper elevation={3}>
        <Tabs
          value={tab}
          variant="fullWidth"
          onChange={(e, v) => {
            setTab(v);
            setLearn([]);
          }}
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          <Tab label="Hiragana" />
          <Tab label="Katakana" />
        </Tabs>
        {loading ? (
          <Progress>
            <CircularProgress />
          </Progress>
        ) : (
          <TableKana data={data} learn={learn} setLearn={setLearn} />
        )}
      </StyledMainPaper>
      <SpeedDial
        ariaLabel="Exercice"
        sx={{ position: "absolute", bottom: 10, right: 10 }}
        icon={<FitnessCenterIcon />}
      >
        <SpeedDialAction
          icon={<QuizIcon />}
          tooltipTitle={"Exercice"}
          disabled={learn.length < 5}
          onClick={() => {
            setStep(0);
            setOpen(true);
          }}
        />
      </SpeedDial>
      <ExerciseKana
        data={data}
        learn={learn}
        open={open}
        setOpen={setOpen}
        step={step}
        setStep={setStep}
      />
    </StyledPage>
  );
};

export default Kana;
