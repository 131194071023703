import styled from "styled-components";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Particles from "../components/learn/particles";
import Conjugation from "../components/learn/conjugation";
import Adjectives from "../components/learn/adjectives";
import Structures from "../components/learn/structures";
import Notes from "../components/learn/notes";

const StyledPage = styled.div`
  display: flex;
  flex-direction: column;

  min-height: 100dvh;
  padding: 70px 10px 20px;

  @media (min-width: 641px) {
    padding: 70px 20px 20px;
  }
`;

const Learn = () => {
  const location = useLocation();
  const lastHash = useRef("");

  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1);
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        document
          .getElementById(lastHash.current)
          ?.scrollIntoView({ behavior: "smooth", block: "start" });
        lastHash.current = "";
      }, 100);
    }
  }, [location]);

  return (
    <StyledPage>
      <Particles />
      <Adjectives />
      <Conjugation />
      <Structures />
      <Notes />
    </StyledPage>
  );
};

export default Learn;
