import styled from "styled-components";
import React from "react";
import Typography from "@mui/material/Typography";
import Furigana from "../components/furigana";
import Divider from "@mui/material/Divider";

const StyledPage = styled.div`
  display: flex;
  flex-direction: column;

  min-height: 100dvh;
  padding: 70px 10px 20px;

  @media (min-width: 641px) {
    padding: 70px 20px 20px;
  }
`;

const StyledContainer = styled.div`
  height: calc(100dvh - 90px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  @media (min-width: 641px) {
    flex-direction: row;
    justify-content: space-evenly;
  }
`;

const StyledImg = styled.img`
  width: 90%;

  @media (min-width: 320px) {
    width: 200px;
  }
  @media (min-width: 481px) {
    width: 350px;
  }
  @media (min-width: 641px) {
    width: 350px;
  }
  @media (min-width: 961px) {
    width: 550px;
  }
`;

const StyledTxt = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px 0 0 0;

  @media (min-width: 641px) {
    padding: 0 0 0 20px;
  }
`;

const Home = () => {
  return (
    <StyledPage>
      <StyledContainer>
        <StyledImg
          src={`https://nihongonomemo.fr/assets/home.jpeg`}
          alt="Un shiba apprends le Japonais"
        />
        <StyledTxt>
          <Furigana
            center
            text="[千里_せんり]の[道_みち]も[一歩_いっぽ]から。"
          />

          <Divider sx={{ width: "150px", margin: "10px 0" }} />
          <Typography variant="h6">
            Un voyage de mille lieues commence par un seul pas.
          </Typography>
          <Divider sx={{ width: "150px", margin: "10px 0" }} />
          <Furigana
            center
            reverse
            text="[せんり_senri] [の_no] [みち_michi] [も_mo] [いっぽ_ippo] [から_kara]。"
          />
        </StyledTxt>
      </StyledContainer>
    </StyledPage>
  );
};

export default Home;
