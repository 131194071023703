import axios from "axios";
import { API } from "../constant";

export const getSyllabaries = async ({ name }) => {
  let url = `/api/syllabaries?filters[name][$eq]=${name}`;
  let pagination = "&pagination[pageSize]=100&pagination[page]=1";

  return await Promise.all([
    axios.get(`${API}${url}&filters[type][$eq]=gojuon${pagination}`),
    axios.get(`${API}${url}&filters[type][$eq]=dakuon${pagination}`),
    axios.get(`${API}${url}&filters[type][$eq]=hadakuon${pagination}`),
    axios.get(`${API}${url}&filters[type][$eq]=yoon${pagination}`),
  ])
    .then((response) => response.map(({ data }) => data.data))
    .catch((err) => console.error(err));
};
