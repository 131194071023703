import styled from "styled-components";
import ResponsiveTable from "../responsiveTable";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

const StyledMainPaper = styled(Paper)`
  scroll-margin: 70px;
  padding: 10px;
  margin-bottom: 10px;

  @media (min-width: 641px) {
    padding: 20px;
  }
`;

const StyledPaper = styled(Paper)`
  padding: 10px;

  @media (min-width: 641px) {
    padding: 20px;
    width: fit-content;
  }
`;

const StyledBox = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
`;

const StyledYB = styled.b`
  color: #ffc107;
`;

const Conjugation = () => {
  return (
    <StyledMainPaper elevation={3} id="conjugation">
      <Typography variant="h4" gutterBottom>
        La Conjugaison
      </Typography>
      <StyledMainPaper>
        <StyledBox>
          <StyledPaper variant="outlined">
            <Typography variant="h5" gutterBottom>
              Groupe 1
            </Typography>
            <Typography variant="body1">
              Tous les verbes, dont ceux en -aru, -uru, -oru
            </Typography>
            <Typography variant="subtitle2">
              ex: いう、かく、はなす、つくる、のる
            </Typography>
            <Typography variant="subtitle2">-る → -ります</Typography>
            <Typography variant="subtitle2">かく → かきます</Typography>
          </StyledPaper>
          <StyledPaper variant="outlined">
            <Typography variant="h5" gutterBottom>
              Groupe 2
            </Typography>
            <Typography variant="body1">
              Tous les verbes en -iru, -eru
            </Typography>
            <Typography variant="subtitle2">ex: みる、たべる</Typography>
            <Typography variant="subtitle2">-る → -ます</Typography>
            <Typography variant="subtitle2">みる → みます</Typography>
          </StyledPaper>
          <StyledPaper variant="outlined">
            <Typography variant="h5" gutterBottom>
              Groupe 3
            </Typography>
            <Typography variant="body1">する et くる</Typography>
            <Typography variant="subtitle2">する → します</Typography>
            <Typography variant="subtitle2">くる → きます</Typography>
          </StyledPaper>
          <StyledPaper variant="outlined">
            <Typography variant="body1">
              Il existe des verbes du groupe 1 même s'ils se terminent par -iru,
              -eru
            </Typography>
            <Typography variant="subtitle2">
              -iru →
              きる・はしる・しる・はいる・いる・うちきる・かぎる・かじる・ちる・くぎる・にぎる・ねじる・まいる・まじる・よこぎる
            </Typography>
            <Typography variant="subtitle2">
              -eru →
              あせる・ける・しゃべる・すべる・すりへる・てる・ねる・ひねる・かえる・へる
            </Typography>
          </StyledPaper>
        </StyledBox>
        <ResponsiveTable
          title={"Groupe 1 (かく)"}
          times={["Passé", "Présent"]}
          affirmatives={[
            <>
              か<StyledYB>きました</StyledYB>
            </>,
            <>
              か<StyledYB>きます</StyledYB>
            </>,
          ]}
          negatives={[
            <>
              か<StyledYB>きませんでした</StyledYB>
            </>,
            <>
              か<StyledYB>きません</StyledYB>
            </>,
          ]}
        />
        <ResponsiveTable
          title={"Groupe 2 (みる)"}
          times={["Passé", "Présent"]}
          affirmatives={[
            <>
              み<StyledYB>ました</StyledYB>
            </>,
            <>
              み<StyledYB>ます</StyledYB>
            </>,
          ]}
          negatives={[
            <>
              み<StyledYB>ませんでした</StyledYB>
            </>,
            <>
              み<StyledYB>ません</StyledYB>
            </>,
          ]}
        />
      </StyledMainPaper>
    </StyledMainPaper>
  );
};

export default Conjugation;
