import axios from "axios";
import { API, BEARER } from "../constant";
import { getToken } from "../helpers";

const token = getToken();

export const getNote = async ({ userId }) => {
  let filters = `filters[$and][0][$or][0][userId][$null]=true&`;
  if (userId) filters += `filters[$and][0][$or][1][userId][$eq]=${userId}&`;

  let url = "/api/notes?";
  let pagination = "pagination[pageSize]=100&pagination[page]=";

  return await axios.get(`${API}${url}${filters}${pagination}1`).then(
    ({
      data: {
        data,
        meta: {
          pagination: { total },
        },
      },
    }) =>
      Promise.all(
        [...Array((total + 99 - ((total - 1) % 100)) / 100 + 1).keys()]
          .slice(2)
          .map((i) => axios.get(`${API}${url}${filters}${pagination}${i}`))
      ).then((nextdata) =>
        nextdata.reduce((acc, curr) => [...acc, ...curr.data.data], [...data])
      )
  );
};

export const postNote = async ({ userId, note }) => {
  return await axios
    .post(
      `${API}/api/notes`,
      { data: { userId, ...note } },
      { headers: { Authorization: `${BEARER} ${token}` } }
    )
    .then(({ data }) => data)
    .catch((err) => console.error(err))
    .finally(() => {});
};

export const putNote = async (id, note) => {
  const result = await axios
    .put(
      `${API}/api/notes/${id}`,
      { data: note },
      { headers: { Authorization: `${BEARER} ${token}` } }
    )
    .then(({ data }) => data)
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {});
  return result;
};

export const deleteNote = async (id) => {
  return await axios
    .delete(`${API}/api/notes/${id}`, {
      headers: { Authorization: `${BEARER} ${token}` },
    })
    .then(({ data }) => data)
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {});
};
