import { useState, useEffect } from "react";
import { useSettingsContext } from "../../hooks/settings/SettingsContext";
import Furigana from "../furigana";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import Button from "@mui/material/Button";

const Cards = ({ hidden, vocabulary, id, result, setExercises, setStep }) => {
  const { furigana } = useSettingsContext();

  const [way, setWay] = useState(true);
  const [question, setQuestion] = useState(null);
  const [choices, setChoices] = useState(null);
  const [answer, setAnswer] = useState(null);

  const shuffle = (list) =>
    list
      .map((value) => ({ value, sort: Math.random() }))
      .toSorted((a, b) => a.sort - b.sort)
      .map(({ value }) => value);

  useEffect(() => {
    setWay(Math.random() < 0.5);
    let correct = vocabulary.find((d) => d.id === id);
    setQuestion(correct);
    let traps = vocabulary.filter(
      (d) =>
        d.attributes.type === correct.attributes.type && d.id !== correct.id
    );
    let list = shuffle(traps).slice(0, 5);
    list.splice((6 * Math.random()) | 0, 0, correct);
    setChoices(list);
  }, [vocabulary, id]);

  return (
    <Box
      sx={{
        height: "400px",
        width: "100%",
        display: hidden ? "none" : "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography variant="h6" gutterBottom>
        Comment dit-on cela en {way ? "Français" : "Japonais"} ?
      </Typography>
      <Chip
        label={
          question && (
            <Furigana
              furigana={furigana}
              center
              text={question.attributes[way ? "japanese" : "french"] || ""}
            />
          )
        }
        sx={{ height: "100px", width: "140px" }}
      />
      <DoubleArrowIcon sx={{ margin: "10px", rotate: "90deg" }} />
      <Stack
        direction="row"
        sx={{
          justifyContent: "center",
          flexWrap: "wrap",
          width: "400px",
          gap: 1,
        }}
      >
        {choices?.map((choice, index) => (
          <Chip
            key={`exercise${choice.id}`}
            variant={answer === choice.id ? "filled" : "outlined"}
            label={
              <Furigana
                furigana={furigana}
                center
                text={choice.attributes[way ? "french" : "japanese"] || ""}
              />
            }
            onClick={() => setAnswer(choice.id)}
            sx={{
              height: "100px",
              width: "125px",
              ...(result !== null && choice.id === answer
                ? { backgroundColor: "red" }
                : {}),
              ...(result !== null && choice.id === id
                ? { backgroundColor: "green" }
                : {}),
            }}
            disabled={result !== null}
          />
        ))}
      </Stack>
      <Box
        sx={{
          width: "90%",
          maxWidth: "500px",
          margin: "10px 0",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Button
          variant="text"
          onClick={() =>
            setExercises((exs) =>
              exs.map((ex) =>
                ex.id !== id ? ex : { ...ex, result: answer === id }
              )
            )
          }
          disabled={answer === null}
          hidden={result !== null}
        >
          Valider
        </Button>
        <Button
          variant="text"
          onClick={() => setStep((s) => s + 1)}
          hidden={result === null}
        >
          Suivant
        </Button>
      </Box>
    </Box>
  );
};

export default Cards;
